export let lines = [
  {
    source: "中国",
    target: "欧洲",
    color: '#2E93FE',
  },
  {
    source: "中国",
    target: "英国",
    color: '#2E93FE',
  },
  {
    source: "中国",
    target: "中东",
    color: '#2E93FE',
  },
  {
    source: "中国",
    target: "非洲",
    color: '#2E93FE',
  },
  {
    source: "中国",
    target: "墨西哥",
    color: '#2E93FE',
  },
  {
    source: "中国",
    target: "南美",
    color: '#2E93FE',
  },
]