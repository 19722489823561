var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hardware"},[_c('div',{staticClass:"title"},[_vm._v("配套硬件")]),_c('div',{staticClass:"productList"},[_c('div',{staticClass:"scrollBox",style:({left:_vm.left+'px'}),on:{"mouseover":_vm.clear,"mouseleave":_vm.scrollBox}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product1.png")}}),_c('div',[_vm._v("收银POS机")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product2.png")}}),_c('div',[_vm._v("小票机")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product3.png")}}),_c('div',[_vm._v("标签打印机")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product5.png")}}),_c('div',[_vm._v("手持机")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product6.png")}}),_c('div',[_vm._v("扫描平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product7.png")}}),_c('div',[_vm._v("扫码一体机")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product8.png")}}),_c('div',[_vm._v("提价器")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productItem"},[_c('img',{attrs:{"src":require("@/assets/product9.png")}}),_c('div',[_vm._v("手持机")])])
}]

export { render, staticRenderFns }