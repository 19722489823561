<template>
  <div class="agreement">
    <div class="content">
      <p style="line-height:150%"
        class="phoneTop"><span style=";line-height:150%;font-size:32px">&nbsp;</span></p>
      <p style="text-align:center;line-height:150%"><strong><span style="line-height: 150%;font-size: 19px">用户使用协议</span></strong></p>
      <p style="text-align:right;line-height:150%"><span style="text-decoration:underline;"><span style="line-height: 150%;color: rgb(255, 0, 0);font-size: 14px"><ins datetime="2021-01-22T16:07">2022年5月23日更新</ins></span></span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:14px">欢迎您使用TOOERP系统软件，本软件由巧数（上海）网络科技有限公司（以下简称“TOOERP系统”或“本软件”）提供产品及技术服务。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">一、特别提示：</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">1.1【条款阅读】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">您在使用TOOERP系统提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容，包括但不限于免除或者限制责任的条款。如您不同意本服务协议及/或随时对其的修改，您应立即停止注册及使用；您一旦使用我们提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括我们对服务协议所做的修改，并成为我们的用户。如您对本协议的条款有疑问，可与我们客服取得联系咨询。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">1.2【条款接受】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序，或以任何方式使用TOOERP系统服务及产品即表示您已充分阅读、理解并同意接受本协议的条款和条件。本协议即构成对您具有法律约束力的文件。如果您不同意本协议或其中任何条款约定，您应立即停止注册程序并停止使用服务。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">1.3【条款修订】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">TOOERP系统有权对本协议进行修订及补充，且无需单独和事先通知您。本协议一旦发生变动，我们将会在TOOERP系统产品或TOOERP系统官网上公布修订及补充后的本协议。如您在本协议修订/补充版本公布后继续使用本软件的，即视为您同意修订后的本协议。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">二、TOOERP系统介绍</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">2.1【产品介绍】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">TOOERP系统旗下系列收银软件、APP及小程序是由TOOERP系统自主开发并享有知识产权等全部合法权益的系列软件产品，包括但不限于TOOERP系统收银软件（含网页后台、Windows客户端、安卓客户端、ios客户端）。本协议项下您可使用的软件产品及服务的类型以您实际向TOOERP系统及授权渠道合作伙伴购买或通过TOOERP系统公布的官方渠道下载或以其他合法方式获取的特定软件产品为准，您理解并同意，您购买或下载上述某一具体的软件产品不意味着您可使用另一特定类型的软件产品。您在购买或下载某一特定软件产品前，请您务必仔细阅读TOOERP系统官方制定、发布的产品说明以购买、 下载符合您实际需求的软件产品类型及版本。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">2.2【产品费用】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">您理解， TOOERP系统及授权合作伙伴仅提供与本软件相关的技术服务， 除此之外产生的其他费用， 包括但不限于：（1）与网络服务有关的设备（如电脑、收银机、手机及其他接入互联网的设备）及所需的费用（如为接入互联网而支付的网络资费等） 均应由您自行负担；（2）本软件中的第三方服务，如短信服务费用、支付服务所产生的支付服务费用等。如因国家法律法规政策变化的原因或产品功能的重大变化，导致TOOERP系统需要向您收取软件服务费之外的其他费用的，TOOERP系统保留和您重新协商议价的权利。（3） TOOERP系统提供的与本软件相关的技术服务的收费方式、收费标准由TOOERP系统根据公司的运营成本、运营策略等综合考虑后独立决定（调整包括但不限于促销、涨价等），TOOERP系统有权单方修改相应的收费方式和收费价格（包括但不限于软件费用、服务器费用、存储空间费用等），若您在购买和续费时，相关收费方式发生变化的，以TOOERP系统实际制定的收费方式为准；相关价格发生了调整的，应以TOOERP系统制定的现时有效的价格为准（但TOOERP系统与您另有约定的情形除外）。您同意您继续操作的行为（包括但不限于继续使用产品或服务，点击确认等），即视为您知悉并同意变更后的收费方式、收费标准。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">三、账号管理规则</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">3.1【注册资格】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">您确认，在您开始注册或使用本软件前，您是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。如您代表法人或其他组织进行注册，视为您已经获得法人或其他组织的授权，您所代表的法人或其他组织同意受本协议约束。若您不具备前述与您的行为相适应的权利、能力或未经过权利人的合法授权，则您和/或您代表的权利人和/或您的监护人应依照法律规定承担因此产生的一切后果。 </span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">3.2【账号注册】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">根据TOOERP系统的注册页面提示，您应当提供手机号码、所属行业、使用版本信息完成注册，请您务必确保提供的注册信息真实、合法、有效、准确、及时、详尽和完整，并不断更新注册信息及相关资料，以符合前述要求。如您提供的资料不准确或TOOERP系统有合理的理由认为该资料不真实、不完整、不准确，TOOERP系统有权暂停或终止您的注册身份及资料，并拒绝您在目前或将来使用TOOERP系统的服务，TOOERP系统对您所提交的资料的安全性不承担任何责任或义务。您在用户注册过程中输入的手机号将成为您找回密码的工具，请您务必输入您本人的真实有效的手机号。如您在注册账号时未使用您本人的真实有效的手机号进行注册 导致密码无法找回，密码泄露或数据泄露等其他任何后果的，TOOERP系统将不承担任何责任。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">3.3【账号安全】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">账号和密码为您登录TOOERP系统产品的唯一有效途径，请您务必妥善保管您的软件账号及密码。如您发现您的注册账号、密码遭他人非法使用，应立即以有效的方式通知TOOERP系统并提供您作为软件账号所有权人的合法证明文件，您同意授权TOOERP系统采取一切合理措施以保证您的利益不受损害，并认可该等措施所产生的法律效果归于您自身。因您的保管疏忽、您的主动泄露、第三人对您的诈骗或第三人违规操作等原因导致您的注册账号、密码泄露所造成的损失及后果，应由您通过司法、行政等救济途径向侵权行为人追责，TOOERP系统不就此承担任何责任。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">3.4【密码找回】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">如您忘记TOOERP系统产品账号的密码，或因您个人原因导致的帐号信息遗失，如需找回帐号信息，您可通过注册时填写的手机号找回密码。如您无法通过手机号找回密码，您须进一步提供其他有效证据证明您为账号的所有权人，TOOERP系统可为您提供密码找回的服务。TOOERP系统拥有独立的权力来判断您提供的证据是否足以充分证明您为账号的所有权人，若提供的信息不符合要求，TOOERP系统有权不予提供找回密码的服务，由此产生的损失和责任由您自行承担。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">3.5【其它说明】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">TOOERP系统提供的互联网在线产品，若您的门店连续60天未登录使用，视为已经停止使用，TOOERP系统有权对您的账号停止服务，并对账号内的数据进行调整（转移或清除）。调整过程中可能存在数据遗失风险，TOOERP系统不就此承担任何责任。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">四、使用规则</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">4.1【软件的获取】 </span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">您应当从TOOERP系统授权的合作伙伴及公布的官方渠道下载或以其他合法方式获取本软件。如果您从未经TOOERP系统授权的第三方获取本软件，TOOERP系统无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。如您从第三方获取本软件的，因您与第三方产生的合同纠纷及争议，应由您和第三方协商解决。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">4.2【软件的更新】 </span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">TOOERP系统保留在任何时候自行决定对其开发的任何软件、版本及其功能进行升级、修改、转移、删除、暂缓或停止提供的权利，TOOERP系统进一步保留开发新的软件、版本或功能的权利。上述所有软件、版本及功能，除非另有说明，否则仍适用本协议。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">4.3【数据存储服务】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">您同意将按照TOOERP系统公布的关于数据存储服务的使用期限、大小限制等相关规则使用相关服务。相关数据存储期限到期或已经不符合相关规则的，TOOERP系统有权删除您不符合相关规则的数据。若由于您在此之前没有主动转移相关数据而被删除造成的损失，与TOOERP系统无关，全部责任由您自行承担。同时，TOOERP系统有权根据公司实际运营需要单方改变相关规则。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">4.</span><span style=";line-height:150%;font-size:16px">4</span><span style="line-height:150%;font-size:16px;">【实名认证】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">为了保障网络安全及交易安全，我们可能会在某些涉及网络及交易安全的功能或服务中设置实名认证的要求，在使用前述功能或服务时，您需要根据TOOERP系统的要求提供您的营业执照、行政许可执照、身份证或其他信息或材料方可使用相应的功能及服务。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">4.</span><span style=";line-height:150%;font-size:16px">5</span><span style="line-height:150%;font-size:16px;">【服务变更】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style=";line-height:150%;font-size:16px">TOOERP系统保留随时变更、中止或终止部分免费服务的权利，并保留根据实际情况随时调整TOOERP系统提供的服务种类、形式，TOOERP系统不承担因TOOERP系统产品提供的任何调整给您造成的损失，尽管有本条约定，TOOERP系统有权在未来恰当的时机对免费服务内容收取相应的服务费用。TOOERP系统保留随时终止向您提供的收费服务的权利，并保留根据实际情况随时调整TOOERP系统提供的收费服务种类和形式。如果TOOERP系统终止提供某项收费服务，TOOERP系统的义务仅在于向您返还您尚未使用的服务期对应的部分费用。但无论如何，TOOERP系统将尽合理的努力给您预留合理的时间以便您为该等服务变更、中止或终止做出应对。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">4.</span><span style=";line-height:150%;font-size:16px">6</span><span style="line-height:150%;font-size:16px;">【禁止信息】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">您在使用TOOERP系统产品的服务时，必须遵守中华人民共和国相关法律法规的规定，您不得利用本软件储存、发布、传播以下信息和内容或从事下述行为：包括但不限于下列行为:</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（1）反对宪法所确定的基本原则，煽动、抗拒、破坏宪法和法律、行政法规实施的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（2）煽动颠覆国家政权，推翻社会主义制度，煽动、分裂国家，破坏国家统一的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（3）损害国家机关信誉的、损害国家荣誉和利益的、损害社会公共利益和涉及国家安全的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（4）煽动民族仇恨、民族歧视，破坏民族团结的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（5）任何包含对种族、性别、宗教、地域内容等歧视的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（6）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（7）宣扬封建迷信、邪教、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（8）骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的、公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（9）其他违反宪法和法律行政法规的。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">4.</span><span style="line-height:150%;font-size:16px">7</span><span style="line-height:150%;font-size:16px;">【处罚规定】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">您违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿TOOERP系统，并使之免受损害。对此，TOOERP系统有权视您的行为性质，采取包括但不限于删除您发布信息内容、暂停使用许可、终止服务、限制使用、回收帐号、追究法律责任等措施。对恶意注册帐号或利用帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，TOOERP系统有权回收其帐号。同时，TOOERP系统会视司法部门的要求，协助调查。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">五、用户信息保护</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">5.1【数据安全】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">对您在本软件存储或发布的信息和数据，包括但不限于您的个人信息、会员信息和业务数据等，我们将进行安全保护，但同时您要保证您存储或发布的信息和数据，均通过合法途径获取或具有合法授权，您保证您有权对您存储和发布的信息和数据对外作出充分授权。因前述信息和数据，引发的第三方向TOOERP系统的索赔、 诉讼、纠纷或行政管理部门的处罚等，均由您负责解决并保证TOOERP系统免责，由此给TOOERP系统造成损失的，须由您承担。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">5.2【个人信息保护】</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">您使用TOOERP系统的服务，即表示您同意TOOERP系统可以按照《隐私政策》处理您的个人信息。TOOERP系统可能会与合作伙伴共同向您提供您所要求的服务或者共同向您展示您可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，您同意TOOERP系统可与其分享必要的信息。并且，TOOERP系统会要求其确保数据安全并且禁止用于任何其他用途。除此之外，TOOERP系统不会向任何无关第三方提供或分享信息。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px;">5.3【使用信息的保护】 </span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">对于您在本软件中存储和发布的非个人信息，包括但不限于商品数据、销售数据等业务数据，TOOERP系统将予以严格保护，不会擅自使用、共享、转让、披露，但以下情形除外：</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（1）获得您的授权和同意的。获得您的明确同意后，我们会按照您授权同意的范围对您的数据进行使用、共享、转让或披露；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（2）为了向您提供和改进本软件的服务，TOOERP系统及其关联公司会使用您的数据用于本软件服务的监测、改进或提供新的产品和服务；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（3）根据法律法规的规定或行政机关、司法机构、安全机关等要求，TOOERP系统会向其提供您的相关数据；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（4）如果您通过本软件使用的某些服务是由第三方服务商提供的，TOOERP系统将与第三方服务商共享向您提供相应产品及服务所必需的信息。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（5）TOOERP系统将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。在此情况下TOOERP系统有权使用去标识化的信息；并在不透露您个人信息的前提下，对用户数据库进行分析并予以商业化应用。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">六、免责条款</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">6.1 遇以下情况，TOOERP系统不承担任何责任，包括但不仅限于：</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（1）因不可抗力、系统故障、通讯故障、网络拥堵、供电系统故障、恶意攻击等造成TOOERP系统未能及时、准确、完整地提供服务。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（2）无论在任何原因下，您通过使用TOOERP系统上的信息或由TOOERP系统产品链接的其他网站上的信息，或其他与TOOERP系统链接的网站上的信息所导致的任何损失或损害。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（3）注册用户被暂停使用等后果。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（4）TOOERP系统提供的服务可能因Internet和电子通信固有的缺陷而产生延迟、限制、或其它问题，TOOERP系统对此不承担责任。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">6.2 如用户利用系统差错、故障或其他原因导致的漏洞，损害TOOERP系统及任何第三方的权益，TOOERP系统将终止该用户资格，并保留法律追究的权利。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">6.3 TOOERP系统保留在任何时候不经通知进行以下任何行为的权利：</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（1）基于任何原因，修改、中止或终止TOOERP系统系列产品或其任何部分的运行或访问。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（2）修改或变更本协议或其任何部分及任何适用政策或条款。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（3）在进行定期或非定期维护、错误纠正或其它变更所必须时，中断TOOERP系统系列产品或其任何部分的运行。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">6.4 适用法律允许的最大范围内，TOOERP系统不就因使用或不能使用TOOERP系统产品所引起的或有关的任何间接的、意外的、直接的、特殊的、惩罚性的或其它任何损害（包括但不限于因人身伤害或财产损坏而造成的损害，因利润损失、数据损失、营业中断、计算机瘫痪或故障、商业信息的遗失而造成的损害，因疏忽而造成的损害，或因任何金钱上的损失或任何其它损失而造成的损害）承担赔偿责任，即使TOOERP系统事先未告知该损害发生的可能性。 您对TOOERP系统产品的使用由您承担全部责任，如果对TOOERP系统或任何内容有任何不满意，您可以停止使用TOOERP系统或任何该等内容， 尽管本协议中可能含有相悖的规定，TOOERP系统对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您在使用TOOERP系统产品服务而支付给TOOERP系统的费用(如有)。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">6.5 TOOERP系统对以下情形不做任何担保，包括但不仅限于：</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（1）TOOERP系统产品一定能满足您的要求。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（2）服务不会受中断。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（3）服务的安全性、及时性、完整性和准确性。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（4）服务所涉及相关方的服务。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（5）您从TOOERP系统收到口头或书面的意见或信息。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（6）您所提供的身份信息一定被注册。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（7）他人使用与您相同的身份信息注册。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（8）在与任何其它硬件、软件、系统或数据结合时的，安全性、及时性、不受干扰或不发生错误。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">（9）缺陷将会被更正。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">6.6 TOOERP系统将尽合理的努力保护您的信息安全，并为此采取合理的数据传输、存储、转换等预防保护措施，但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，TOOERP系统不承担任何赔偿责任。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">七、知识产权</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px"><span>TOOERP系统所有内容和资源的版权归TOOERP系统所有(除非TOOERP系统已经标明版权所有人)，所有信息受《中华人民共和国著作权法》及相关法律法规和中国加入的所有知识产权方面的国际条约的保护。未经TOOERP系统事先的书面许可，任何单位和个人不得就TOOERP系统产品上的相关资源以任何方式、任何文字做全部或局部复制、修改、发送、储存、发布、交流或分发，或利用TOOERP系统产品上的相关资源创建其他商业用途的资源，否则TOOERP系统将追究其法律责任。</span></span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">您保证（包括但不限于）：</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">（1）不对TOOERP系统产品作改变、反编译、反汇编等反向工程处理；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">（2）不试图进行任何获得TOOERP系统产品源代码的访问或行为;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">（3）不除掉或掩盖有关软件著作权或商标的标志；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">（4）不做其他超越或违反本协议规定行使软件权利的行为。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">八、信息通知</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">8.1 您在此同意，关于本软件的产品和服务、 您使用本软件服务相关的通知、 本协议的更新等所有通知，均可以通过网站公告、软件公布、电子邮件、短信、微信或信件传达等任一方式进行。</span><span style=";line-height:150%;font-size:16px"><br></span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p><span style="line-height:150%;font-size:16px">8.2 上述通知于发出或公告之日即视为到达您。</span>
      <p></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">九、协议终止</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">TOOERP系统有权在下列情形下拒绝您的订购或终止本协议，而无需承担任何责任：</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">9.1 您违反了本协议的任一条款，且自TOOERP系统通知您纠正后的合理期限内仍未纠正的；</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">9.2 TOOERP系统或TOOERP系统授权的第三方无法确认您提供的注册信息的真实性的；</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height: 150%;font-size: 16px">9.3 TOOERP系统授予您的软件使用许可期限届满而您未与TOOERP系统正规渠道进行续签。</span></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">如果您的服务被终止，TOOERP系统可以从服务器上永久地删除您的数据，且服务终止后TOOERP系统没有义务向您返还数据，对由此所可能产生的责任与损失，TOOERP系统无须承担任何责任。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">十、第三方服务或链接</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">TOOERP系统的产品和服务中可能含有第三方产品和/或服务的链接或信息。如果您将个人信息填写在其他平台上或通过第三方程序提交/发布，则该方可能会获知您的信息。该第三方应用程序可能有其相应的隐私政策规定，请仔细查看，并在提供个人信息前请仔细考虑清楚。本协议不适用于第三方向您收集信息的行为，TOOERP系统无法控制第三方的行为，因此也不会对第三方收集、使用您信息的行为承担责任。 为确保“您的资料”的安全，TOOERP系统向TOOERP系统全体员工传达了公司的隐私和安全准则，并在公司内部严格执行隐私保护措施。TOOERP系统不会向您所使用服务所涉及相关方之外的其他方公开或透露您的个人资料，法律法规规定除外。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px;">十一、法律及争议解决</span></strong></p>
      <p style="line-height:150%"><span style=";line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">本协议适用中华人民共和国法律。协议各方当事人对本协议有关权利、义务和责任之条款的法律含义有准确无误的理解，在此基础上，各方自愿签署本协议。如遇本协议有关的某一特定事项缺乏明确法律规定，则应参照通用国际商业惯例和（或）行业惯例。因双方就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，任何一方均有权向TOOERP系统所在地有管辖权的人民法院起诉。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p><br></p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.agreement {
  background-color: rgb(214, 236, 251);
  min-height: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
}
ul {
  list-style: none;
}
.content {
  width: 860px;
  background: none repeat scroll 0 0 #fefefe;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  height: auto;
  padding: 10px 20px;
  padding-top: 40px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .phoneTop {
    line-height: 0 !important;
    display: inline;
    span {
      line-height: 0 !important;
    }
  }
  .agreement {
    padding-top: 30px;
  }
  .content {
    width: 100%;
  }
}
</style>
