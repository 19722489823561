<template>
  <div class="agreement">
    <div class="content">
      <p style="line-height:150%"
        class="phoneTop"><span style="line-height:150%;font-size:32px">&nbsp;</span></p>
      <p style="text-align:center;line-height:150%"><strong><span style="line-height: 150%;font-size: 19px"><span style="font-weight:bold;">个人隐私政策</span></span></strong></p>
      <p style="text-align:right;line-height:150%"><span style="text-decoration:underline;"><span style="line-height: 150%;color: rgb(255, 0, 0);font-size: 14px"><ins datetime="2021-01-22T16:07">2022年5月23日更新</ins></span></span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">特别提示</span></span></strong></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">巧数（上海）网络科技有限公司（以下简称“我们”）非常注重保护用户（以下简称“您”）的个人信息，我们深知个人信息对您的重要性，我们将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。有鉴于此，我们制定本《隐私政策》（以下简称“本政策”）并提醒您：本政策适用于TOOERP产品及服务（以下简称“本服务”）</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">需要特别说明的是，本政策不适用于其他第三方向您提供的服务。例如TOOERP产品中接入的第三方服务，您向第三方服务商提供的个人信息不适用本政策。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">在使用本服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务，一旦您开始使用我们产品或服务，即表示您已充分理解并同意本政策。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">隐私政策</span></span></strong></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">1、我们如何收集和使用您的个人信息</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">2、我们如何使用Cookie和同类技术</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">3、我们如何共享、转让、公开披露您的个人信息</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">4、我们如何保护您的个人信息</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">5、您如何管理您的个人信息</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">6、我们如何处理未成年人的个人信息</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">7、本隐私政策如何更新</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">8、如何联系我们</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">一、我们如何收集和使用您的信息</span></span></strong></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px"><span>我们会出于本政策所述的以下目的，收集和使用您的个人信息：</span> </span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">（一）向您提供TOOERP服务</span></span></strong></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">1、您向我们提供的信息</span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px"><span>为便于向您提供产品服务，我们有权从身份校验、完成交易、售后及客户服务、提供安全保障等多种角度，收集您向我们提供的联系人信息（姓名、性别、电话号码等）、营业执照信息、法定代表人信息、电子邮箱、银行账户或其他支付工具的账户信息等，您在使用TOOERP产品服务的过程中所提交的任何文字、照片等各种形式的信息，也可能会包含或者关联到您的个人信息，我们将对于上述信息中涉及的个人信息予以严格保护。</span></span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">2、我们在您使用服务过程中收集的信息</span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账户异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联， 这些信息包括：</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本等软硬件特征和设备环境信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height: 150%;font-size: 16px">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">（二）为您提供安全保障</span></span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更准确地预防钓鱼网站欺诈和木马病毒，更准确地识别违反法律法规或TOOERP产品用户服务协议的情况，我们可能使用或整合您的个人信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">（三）其它用途</span></span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">（四）征得授权同意的例外</span></span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">1、与国家安全、国防安全有关的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">2、与公共安全、公共卫生、重大公共利益有关的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">3、与犯罪侦查、起诉、审判和判决执行等有关的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">5、所收集的个人信息是个人信息主体自行向社会公众公开的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">7、根据您的要求签订合同所必需的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">9、为合法的新闻报道所必需的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">11、法律法规规定的其他情形。</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">&nbsp;</span></strong></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">二、我们如何使用Cookie和同类技术</span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">当您使用本软件时，我们可能会利用cookie和同类技术收集您的信息用于改善产品服务和用户体验，及时发现并防范安全风险。我们不会将cookie用于本政策所述目的之外的任何用途，您可根据自己的偏好留存或删除cookie。您可清除软件内保留的所有cookie，当您手动清除后您的相关信息已经删除。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">三、我们如何共享、 转让、 公开披露您的个人信息</span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">（一）共享</span></span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">我们不会与我们以外的公司、组织和个人共享您的个人信息，但以下情况除外：</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">1、在获取您的明确同意或授权情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">2、在法定情形下共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">3、与关联公司共享：与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求你的授权同意。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">4、我们会委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到为其履行职责所需信息，且不能将此信息用于任何其他目的。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">目前，我们的授权合作伙伴包括以下类型：</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">①广告、分析服务类的授权合作伙伴。未经您的授权，我们不会将您的个人信息与提供广告、分析服务的合作伙伴共享。但我们可能会使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助其在不识别您个人的前提下提升广告及服务信息的有效触达率。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">②供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴， 这些支持包括提供技术基础设施服务、 分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、改善和推广我们的产品和服务或进行学术研究和调查。对我们与之共享个人信息的公司、 组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">（二）转让</span></span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px"><span>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span></span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">2、在我们发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">（三） 公开披露</span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px"><span>我们仅会在以下情况下，公开披露您的个人信息：</span> </span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">2、如果我们确定您出现违反法律法规或严重违反我们相关协议规则的情况，或为保护我们及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或我们相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及我们已对您采取的措施。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px"><span>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span> </span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">1、与国家安全、国防安全有关的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">2、 与公共安全、公共卫生、重大公共利益有关的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">3、 与犯罪侦查、起诉、审判和判决执行等有关的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">4、 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">5、 您自行向社会公众公开的个人信息；</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">6、 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、 转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">四、我们如何保护您的个人信息安全</span></span></strong></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">（一）我们有先进的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">（二）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">（三）互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">（四）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">五、您如何管理您的个人信息</span></span></strong><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px"><span>（一）访问您的个人信息</span></span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">您有权访问您的个人信息，法律法规规定的例外情况除外。如果您希望访问或编辑您的账户中的个人基本资料信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过“系统管理”执行此类操作</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">（二）您有权更正或补充您的个人信息</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">（三）您有权删除您的个人信息。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">（四）您有权改变您授权同意的范围。</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">如果您认为我们存在违反法律的规定收集、使用您个人信息的情形，您可以通过我们客服与我们取得联系。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span>六、</span> <span style="font-weight:bold;">我们如何处理未成年人的个人信息</span> </span></strong></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px"><span style="font-weight:bold;">七、 本隐私权政策如何更新</span></span></strong></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">我们提供的服务和产品会不时更新和调整，隐私政策也将随之可能变更，对于重大变更，我们会提供更为显著的通知（包括我们会通过我们公示的方式进行通知甚至向您提供弹窗提示），请您及时查看最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。本隐私权政策最终解释权归本公司所有。</span></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="line-height:150%"><strong><span style="line-height: 150%;font-size: 16px">八、 如何联系我们</span></strong></p>
      <p style="line-height:150%"><span style="line-height:150%;font-size:16px">&nbsp;</span></p>
      <p style="text-indent:32px;line-height:150%"><span style="line-height:150%;font-size:16px">您可以通过我们的官方网站发布的联系方式与我们联系并作充分描述，我们将在验证您身份后的30天内答复您的请求并尽力解决。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过被告住所地有管辖权的法院提起诉讼来寻求解决方案。</span></p>
      <p><br></p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.agreement {
  background-color: rgb(214, 236, 251);
  min-height: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
}
ul {
  list-style: none;
}
.content {
  width: 860px;
  background: none repeat scroll 0 0 #fefefe;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  height: auto;
  padding: 10px 20px;
  padding-top: 40px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .phoneTop {
    line-height: 0 !important;
    display: inline;
    span {
      line-height: 0 !important;
    }
  }
  .agreement {
    padding-top: 30px;
  }
  .content {
    width: 100%;
  }
}
</style>
