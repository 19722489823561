<template>
  <div>
    <div class="try">
      <div class="title">立即免费试用</div>
      <div class="theInput">
        <input type="text"
          placeholder="请填写你的手机号"
          v-model="phone">
        <div class="tryButton"
          @click="show = true">免费试用</div>
      </div>
    </div>
    <div class="linkUs">
      <div class="center">
        <div class="left">
          <div class="logo">
            <img src="@/assets/navLogo.png">
            <span>TOOERP-巧数科技</span>
          </div>
          <div class="aboutUs">
            <span>巧数（上海）网络科技有限公司</span><br>
            <span>
              巧数科技成立于2016年，总部位于上海，全球拥有
              30多名专业员工，并在全球设有多个专属服务网
              点，以满足华商跨区域经营的需求。过去的7年里，
              我们一直专注于为华商提供提供一站式的解决方案。
            </span>
          </div>
        </div>
        <div class="right">
          <div>
            <div class="title">联系我们</div>
            <div class="codeBox">
              <div>
                <img src="@/assets/code1.png"
                  class="kefu">
                <div class="flagBox">
                  <img src="@/assets/IndexFlag1.png"
                    class="flag">
                  <span>上海（总部）</span>
                </div>
                <div class="address">
                  上海市浦东新区<br>御北路385号<br>2幢
                </div>
              </div>
              <div>
                <img src="@/assets/code4.png"
                  class="kefu">
                <div class="flagBox">
                  <img src="@/assets/IndexFlag1.png"
                    class="flag">
                  <span>义乌（分部）</span>
                </div>
                <div class="address">
                  浙江省义乌市<br> 曙光大厦B座1705
                </div>
              </div>
              <div>
                <img src="@/assets/code3.png"
                  class="kefu">
                <div class="flagBox">
                  <img src="@/assets/IndexFlag2.png"
                    class="flag">
                  <span>欧洲</span>
                </div>
                <div class="address">
                  匈牙利,<br> Budapest, Szállás u. 21-10 épület, 1107，银河东盛10号
                </div>
              </div>
              <div>
                <img src="@/assets/code2.png"
                  class="kefu">
                <div class="flagBox">
                  <img src="@/assets/IndexFlag3.png"
                    class="flag">
                  <span>拉美</span>
                </div>
                <div class="address">
                  智利, 圣地亚哥,<br> Gorbea <br> 2707 华夏楼,<br> dep211
                </div>
              </div>
              <div>
                <img src="@/assets/code2.png"
                  class="kefu">
                <div class="flagBox">
                  <img src="@/assets/IndexFlag4.png"
                    class="flag">
                  <span>墨西哥</span>
                </div>
                <div class="address">
                  Dr.Valenzuela <br> 28, Doctores, Cuauhtémoc,<br> 06720 Ciudad de México,<br> CDMX
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <tryUseDialog :show="show"
      @close="show = false"
      :phone="phone"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import tryUseDialog from '@/components/tryUseDialog'
export default {
  name: '',
  props: {},
  components: {
    tryUseDialog
  },
  data() {
    return {
      left: 0,
      scrollType: 'left',
      timer: null,
      show: false,
      phone: ''
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    scrollBox() {
      // this.timer = setInterval(() => {
      //   if (this.left >= 60) {
      //     this.scrollType = 'left'
      //   }
      //   if (this.left == -280) {
      //     this.scrollType = 'right'
      //   }
      //   if (this.scrollType == 'left') {
      //     this.left--
      //   } else {
      //     this.left++
      //   }
      // }, 10)
    },
    clear() {
      console.log(789)
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped lang="scss">
.try {
  background: url(../assets/indexTryBg.png) no-repeat;
  background-size: 100% 100%;
  height: 320px;
  padding-top: 71px;
  padding-bottom: 104px;
  .title {
    text-align: center;
    // width: 316px;
    height: 42px;
    font-size: 30px;
    // font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 42px;
    letter-spacing: 1px;
    margin: 0 auto;
  }
  .theInput {
    width: 580px;
    height: 70px;
    background: #ffffff;
    border-radius: 39px;
    margin: 0 auto;
    margin-top: 33px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    input {
      height: 50px;
      flex-grow: 1;
      border: none;
      font-size: 18px;
      &:focus {
        outline: none;
      }
    }
    .tryButton {
      width: 150px;
      height: 60px;
      background: #2e93fe;
      border-radius: 39px;
      margin-right: 5px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 28px;
      letter-spacing: 1px;
      text-align: center;
      line-height: 60px;
      &:hover {
        background: #40a9ff;
      }
    }
  }
}

.linkUs {
  color: white;
  background: #021325;
  width: 100%;
  padding-bottom: 50px;
  > .center {
    padding-top: 65px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      // width: 30%;

      .logo {
        display: flex;
        align-items: center;
        img {
          margin-right: 13px;
          width: 62px;
          height: 62px;
        }
        span {
          font-size: 22px;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
          letter-spacing: 1px;
        }
      }
    }
    .right {
      width: 800px;
      // float: right;
      display: flex;
      justify-content: flex-end;
      > div {
        width: 800px;
      }
    }

    .aboutUs {
      width: 414px;
      margin-top: 14px;
      font-size: 18px;
      font-weight: 400;
      line-height: 36px;
      color: #ffffff;
      > span {
        &:first-child {
          color: #2b9cf9;
        }
      }
    }
    .codeBox {
      margin-top: 37px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      > div {
        width: 15%;
      }
      .kefu {
        width: 100%;
      }
      .flagBox {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .flag {
          width: 32px;
          height: 21px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .try {
    width: 100%;
    height: auto;

    padding-top: 2rem;
    padding-bottom: 5rem;
    .title {
      text-align: center;
      font-size: 2rem;
      line-height: 42px;
      letter-spacing: 1px;
      margin: 0 auto;
    }
    .theInput {
      width: 90%;
      height: 5rem;
      padding-left: 1.6rem;
      input {
        height: 4rem;
        font-size: 1.4rem;
        &:focus {
          border: none;
        }
      }
      .tryButton {
        width: 8rem;
        height: 4rem;
        margin-right: 0.4rem;
        font-size: 1.2rem;
        line-height: 4rem;
      }
    }
  }
  .linkUs {
    height: auto;
    > .center {
      width: 100%;
      flex-wrap: wrap;
      padding-top: 5rem;
      .aboutUs {
        padding: 0.8rem;
        width: 100%;
        height: auto;
        margin: 0;
        font-size: 1.4rem;
      }
      .left {
        width: 100%;
      }
      .right {
        margin-top: 6rem;
        width: 100%;
        margin: 0;
        .title {
          font-size: 22px;
          margin-left: 0.6rem;
        }
        > div {
          width: 100%;
        }
      }
      .codeBox {
        height: auto;
        width: 100%;
        overflow: visible;
        justify-content: space-around;
        position: static;
        flex-wrap: wrap;
        > div {
          width: 31%;
          margin-right: 1px;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>
