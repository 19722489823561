<template>
  <div class="service">
    <iframe src="https://doc.k2046.com/"
      frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.service {
  height: 100%;
  overflow: hidden;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>
