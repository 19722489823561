<template>
  <div class="index"
    @click="changeNum++">
    <!-- <div class="centerBox content1">
      <div class="content1_left">
        <div class="title">
          做华商，批发、零售，就用TOOERP
        </div>
        <div class="textContent">
          专为海外华商业务痛点开发的进销存管理系统，助力华商经营海外业务。
        </div>
        <div class="tryUse"
          @click="show = true">
          免费试用
        </div>
      </div>
      <img class="content1_right"
        src="@/assets/Index1.png"
        alt="">
      <div class="phone content1_left">
        <div class="title">做华商,批发、零售,就用TOOERP</div>
        <div class="textContent">
          专为海外华商业务痛点开发的进销存管理系统，助力华商经营海外业务。
        </div>
        <div class="tryUse hoverBtn"
          @click="show = true">
          免费试用
        </div>
      </div>
    </div> -->
    <div class="banner">
      <img src="@/assets/Index1.png"
        class="computer">
      <img src="@/assets/Index1Phone.png"
        class="phone">
      <div class="banner_left computer">
        <div class="title">
          做华商，批发、零售，就用TOOERP
        </div>
        <div class="textContent">
          专为海外华商业务痛点开发的进销存管理系统，助力华商经营海外业务。
        </div>
        <div class="tryUse"
          @click="show = true">
          免费试用
        </div>
      </div>
    </div>
    <div class="langBox">
      <div class="centerBox content2">
        <img class="content2_left"
          src="@/assets/Index2.png"
          alt="">
        <div class="content2_right">
          <div class="title">
            经过6万华商检验的TOOERP
          </div>
          <ul>
            <li>
              <div class="ulTitle">
                <div class="dot">
                  <div></div>
                </div>
                <div class="text">全链路的业务支持</div>
              </div>
              <div class="intro">
                <div class="dot">
                  <div></div>
                </div>
                从采购、库存，到销售、发货，贯穿华商贸易全业务链路，一套系统解决所有问题
              </div>
            </li>
            <li>
              <div class="ulTitle">
                <div class="dot">
                  <div></div>
                </div>
                <div class="text">灵活的价格体系</div>
              </div>
              <div class="intro">
                <div class="dot">
                  <div></div>
                </div>
                不同的销售渠道，不同的价格策略，给予您最大的灵活
              </div>
            </li>
            <li>
              <div class="ulTitle">
                <div class="dot">
                  <div></div>
                </div>
                <div class="text">强大的结算方式</div>
              </div>
              <div class="intro">
                <div class="dot">
                  <div></div>
                </div>
                支持多币种、支持多支付方式、支持赊销、支持预付货款，满足您各种情况下的结算需求
              </div>
            </li>
            <li>
              <div class="ulTitle">
                <div class="dot">
                  <div></div>
                </div>
                <div class="text">全场景权限管理</div>
              </div>
              <div class="intro">
                <div class="dot">
                  <div></div>
                </div>
                根据海外经营的实际情况，提供极其细致的权限设置，为您的生意安全加把锁
              </div>
            </li>
            <li>
              <div class="ulTitle">
                <div class="dot">
                  <div></div>
                </div>
                <div class="text">符合本地合格要求</div>
              </div>
              <div class="intro">
                <div class="dot">
                  <div></div>
                </div>
                我们对接本土发票和税控系统，确保您的经营合规性，让您无后顾之忧
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="centerBox content3">
      <div class="content3_left">
        <div class="title">
          支持全部销售场景的TOOERP
        </div>
        <ul>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">批发市场</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              门店订单式销售、仓库自选式销售、手机端销售，帮助您在任何地方做生意
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">零售门店</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              POS机扫码销售、电脑端扫码销售，本地雇员更习惯、华商老板更明白的收银系统
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">在线销售
              </div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              网页商城销售、APP商城销售、电商独立站销售，客户不到店里一样下单，生意从此不再守株待兔
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">离线销售</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              断网时也能继续做生意，网络不好也不怕
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">上门推销</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              推销员APP销售，随身携带产品资料，随时、随地提升业绩，查看销售提成
            </div>
          </li>
        </ul>
      </div>
      <img class="content2_right"
        src="@/assets/Index3.png"
        alt="">
      <div class="content3_left phone">
        <div class="title">
          支持全部销售场景的TOOERP
        </div>
        <ul>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">批发市场</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              门店订单式销售、仓库自选式销售、手机端销售，帮助您在任何地方做生意
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">零售门店</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              POS机扫码销售、电脑端扫码销售，本地雇员更习惯、华商老板更明白的收银系统
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">在线销售
              </div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              网页商城销售、APP商城销售、电商独立站销售，客户不到店里一样下单，生意从此不再守株待兔
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">离线销售</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              断网时也能继续做生意，网络不好也不怕
            </div>
          </li>
          <li>
            <div class="ulTitle">
              <div class="dot">
                <div></div>
              </div>
              <div class="text">上门推销</div>
            </div>
            <div class="intro">
              <div class="dot">
                <div></div>
              </div>
              推销员APP销售，随身携带产品资料，随时、随地提升业绩，查看销售提成
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="langBox">
      <div class="centerBox content4">
        <div class="title">
          客户成功的TOOERP
          <div></div>
        </div>
        <div class="content4_list">
          <div>
            <img src="@/assets/Index4.png"
              alt="">
            <div class="itemTitle">本地化服务</div>
            <div class="Textcontent">无论您身在哪个国家，我们都可提供上门安装和教学服务</div>
          </div>
          <div>
            <img src="@/assets/Index5.png"
              alt="">
            <div class="itemTitle">全天候支持</div>
            <div class="Textcontent">我们提供7*24小时的在线技术支持，覆盖全球各个时区，确保您
              随时获得帮助</div>
          </div>
          <div>
            <img src="@/assets/Index6.png"
              alt="">
            <div class="itemTitle">对结果负责</div>
            <div class="Textcontent">您的成功使用，是我们的责任</div>
          </div>
        </div>
      </div>
    </div>
    <div class="productShow content0">
      <div class="theMiddle">
        <div class="tabsTitle">配套硬件</div>
        <div class="relativeBox"
          @mouseover="clear"
          @mouseleave="scrollBox">
          <div class="productList"
            :style="{left:left+'px'}">
            <div class="productItem"
              v-for="(item,index) in productArr"
              :key="index">
              <div class="title">{{item.title}}</div>
              <img :src="item.img">
            </div>
          </div>
        </div>
        <div class="relativeBox2">
          <div class="productList">
            <div class="productItem"
              v-for="(item,index) in productArr"
              :key="index">
              <div class="title">{{item.title}}</div>
              <img :src="item.img">
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomBar></bottomBar>
    <tryUseDialog :show="show"
      @close="show = false"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import sideBar from '@/components/sideBar'
import bottomBar from '@/components/bottomBar'
import tryUseDialog from '@/components/tryUseDialog'
export default {
  name: '',
  data() {
    return {
      show: false,
      changeNum: 0,
      // 硬件
      productArr: [
        {
          title: '手持机',
          img: require('@/assets/index/product5.png')
        },
        {
          title: '小票机',
          img: require('@/assets/index/product2.png')
        },
        {
          title: '扫描平台',
          img: require('@/assets/index/product6.png')
        },
        {
          title: '收银POS机',
          img: require('@/assets/index/product1.png')
        },
        {
          title: '手持机',
          img: require('@/assets/index/product9.png')
        },
        {
          title: '提价器',
          img: require('@/assets/index/product8.png')
        },
        {
          title: '扫码一体机',
          img: require('@/assets/index/product7.png')
        },
        {
          title: '标签打印机',
          img: require('@/assets/index/product3.png')
        },
        {
          title: '便携标签机',
          img: require('@/assets/index/product4.png')
        }
      ],
      left: 0,
      scrollType: 'left',
      timer: null
    }
  },
  components: {
    sideBar,
    bottomBar,
    tryUseDialog
  },
  created() {
    this.scrollBox()
  },
  mounted() {},
  methods: {
    scrollBox() {
      this.timer = setInterval(() => {
        if (this.left == 0) {
          this.scrollType = 'left'
        }
        if (this.left == -580) {
          this.scrollType = 'right'
        }
        if (this.scrollType == 'left') {
          this.left--
        } else {
          this.left++
        }
      }, 10)
    },
    clear() {
      console.log(789)
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped lang="scss">
.centerBox {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.langBox {
  width: 100%;
  background: #f8fcff;
}
.banner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .banner_left {
    left: 147px;
    position: absolute;
    top: 40%;
    margin-right: 66px;
    .title {
      font-size: 29px;
      font-weight: 500;
      color: #092e53;
      line-height: 40px;
      letter-spacing: 1px;
      margin-bottom: 11px;
    }
    .textContent {
      font-size: 14px;
      font-weight: 400;
      color: #092e53;
      line-height: 27px;
      letter-spacing: 1px;
      margin-bottom: 30px;
    }
    .tryUse {
      width: 120px;
      height: 43px;
      background: #2e93fe;
      border: 1px solid #2b9cf9;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 43px;
      letter-spacing: 1px;
      text-align: center;
    }
  }
}
.content3,
.content2 {
  padding: 75px 0 67px 0;
  justify-content: center;
  align-items: center;

  img {
    width: 552px;
    height: 462px;
  }
  .content2_right,
  .content3_left {
    margin-left: 53px;
    .title {
      font-size: 29px;
      font-weight: 500;
      color: #333333;
      line-height: 40px;
      letter-spacing: 1px;
      margin-bottom: 16px;
    }
    ul {
      list-style: none;
    }
    .ulTitle {
      display: flex;
      align-items: center;
      .dot {
        margin-right: 12px;
        width: 16px;
        height: 16px;
        background: rgba(43, 156, 249, 0.1);
        border: 1px solid rgba(43, 156, 249, 0.1);
        > div {
          width: 6px;
          height: 6px;
          background: #2e93fe;
          border: 1px solid #2b9cf9;
          margin: 0 auto;
          margin-top: 5px;
        }
      }
      .text {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
        letter-spacing: 1px;
      }
    }
    .intro {
      display: flex;
      align-items: center;
      .dot {
        margin-right: 12px;
        width: 16px;
        height: 16px;
        background: #f8fcff;
        > div {
          width: 6px;
          height: 6px;
          background: #f8fcff;
          margin: 0 auto;
          margin-top: 6px;
        }
      }
      font-size: 14px;
      font-weight: 400;
      color: #888888;
      line-height: 22px;
      margin-top: 6px;
      margin-bottom: 13px;
    }
  }
}
.content3 {
  background: none;
  margin: 0 auto;
  width: 100%;
  padding: 100px 0 98px 0;
  .center {
    padding-left: 51px;
    padding-bottom: 45px;
  }
  img {
    width: 552px;
    height: 392px;
    margin-left: 34px;
  }
}

.content4 {
  padding: 36px 0 98px 0;
  display: block;
  .title {
    width: 281px;
    margin: 0 auto;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      width: 90%;
      margin: 0 auto;
      margin-top: 18px;
      border-bottom: 6px #2e93fe solid;
    }
  }
  .content4_list {
    margin-top: 60px;
    display: flex;
    justify-content: space-around;
    > div {
      text-align: center;
      img {
        width: 134px;
        height: 134px;
      }
      .itemTitle {
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
        letter-spacing: 1px;
        margin: 14px 0 12px 0;
      }
      .Textcontent {
        width: 242px;
        font-size: 14px;
        font-weight: 500;
        color: #888888;
        line-height: 26px;
      }
    }
  }
}

.phone {
  display: none;
}

.productShow {
  height: 500px;
  background: #f5f5f7;
  padding-top: 85px;
  padding-bottom: 38px;
  .tabsTitle {
    margin-bottom: 38px;
    font-size: 40px;
    text-align: center;
    font-weight: 800;
    color: #1c3e5d;
    line-height: 56px;
    letter-spacing: 2px;
  }
  .relativeBox {
    position: relative;
    width: 1000px;
    height: 260px;
    overflow: hidden;
  }
  .relativeBox2 {
    display: none;
  }
  .productList {
    position: absolute;
    left: 0;
    display: flex;
    .productItem {
      position: relative;
      padding-top: 20px;
      text-align: center;
      width: 180px;
      height: 260px;
      background: #ffffff;
      border-radius: 14px;
      margin-right: 20px;
      .title {
        font-weight: bold;
        font-size: 16px;
      }
      img {
        width: 140px;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .computer {
    display: none;
  }
  .phone {
    display: block;
  }
  .centerBox {
    width: 100%;
  }
  .content1 {
    flex-wrap: wrap;
    padding: 1.4rem;
    .content1_left {
      display: none;
    }
    img {
      width: 90%;
      height: auto;
      margin: 0 auto;
    }
    .phone {
      text-align: center;
      margin: 0 auto;
      width: 100%;
      display: block;
      margin-top: 30px;
      .title {
        width: auto;
        height: auto;
        font-size: 2rem;
      }
      .textContent {
        margin-top: 11px;
        width: auto;
        height: auto;
        font-size: 1.4rem;
      }
      .tryUse {
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }
  .content2,
  .content3 {
    // .center {
    padding: 2rem;
    width: 100%;
    flex-wrap: wrap;
    img {
      width: 90%;
      height: auto;
      margin: 0;
    }
    .content2_right {
      margin: 0;
      width: 100%;
    }
    .right {
      width: 100%;
    }
    .title {
      width: 100%;
      height: auto;
      font-size: 2.4rem;
      text-align: center;
    }
    .title2 {
      width: auto;
      height: auto;
      font-size: 1.8rem;
      text-align: center;
      margin: 0;
      margin-bottom: 1.2rem;
    }
    .ulTitle {
      display: flex;
      align-items: center;

      .dot {
        margin-right: 12px;
        width: 10px;
        height: 10px;
        > div {
          width: 4px;
          height: 4px;
          margin-top: 2px;
        }
      }
      .text {
        width: auto;
        height: auto;
        font-size: 1.6rem;
      }
    }
    .intro {
      width: auto;
      font-size: auto;
      font-size: 1.4rem;
      text-align: left;
    }
    // }
  }
  .content3 {
    .content3_left {
      display: none;
    }
    img {
      width: 100%;
      height: auto !important;
      margin: 0;
    }
    .phone {
      margin: 0;
      display: block;
    }
  }
  .content4 {
    padding: 2rem;
    .content4_list {
      flex-wrap: wrap;
    }
  }

  .more {
    width: 100%;
    overflow: hidden;
    > div {
      flex-wrap: wrap;
    }
    .item {
      width: 90%;
      margin: 0 auto !important;
      margin-bottom: 1.8rem !important;
    }
    .intro {
      font-size: 1.4rem !important;
      height: auto !important;
    }
    .twoLine {
      margin: 0;
    }
  }

  .theMiddle {
    width: 100%;
    overflow: hidden;
  }
  .productShow {
    height: auto;
    padding-top: 1.8rem !important;
    .tabsTitle {
      font-size: 2.8rem !important;
      margin-bottom: 1rem;
    }
    .relativeBox {
      display: none;
    }
    .relativeBox2 {
      height: auto;
      display: block;
      .productList {
        position: static;
        display: flex;
        flex-wrap: wrap;
        > div {
          padding-top: 1rem;
          width: 30%;
          margin: 0;
          margin-right: 4%;
          margin-top: 2rem;
          height: 18rem;
          img {
            margin-top: 1rem;
            width: 90%;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        // .explain {
        //   display: none !important;
        // }
      }
    }
  }
}
</style>
