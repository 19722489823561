<template>
  <div class="restaurant">
    <div class="banner">
      <img src="@/assets/Restaurant1.png"
        class="computer">
      <img src="@/assets/Restaurant1Phone.png"
        class="phone">
      <div class="banner_text computer">
        <div class="banner_title">餐馆收银点餐系统</div>
        <div class="banner_subtitle">从收银台到餐桌，每一步都为您精心打造</div>
        <div class="tryUse"
          @click="show = true">
          免费试用
        </div>
      </div>
    </div>
    <div class="langBox">
      <div class="centerBox content1">
        <div class="title">
          适合各种业态
          <div></div>
        </div>
        <div class="content1_body">
          <div class="content1_body_item">
            <img src="@/assets/Restaurant2.png">
            <div>
              <img src="@/assets/RestayranIcon1.png">
              <div>正餐馆</div>
            </div>
          </div>
          <div class="content1_body_item">
            <img src="@/assets/Restaurant3.png">
            <div>
              <img src="@/assets/RestayranIcon2.png">
              <div>快餐馆</div>
            </div>
          </div>
          <div class="content1_body_item">
            <img src="@/assets/Restaurant4.png">
            <div>
              <img src="@/assets/RestayranIcon3.png">
              <div>奶茶店</div>
            </div>
          </div>
          <div class="content1_body_item">
            <img src="@/assets/Restaurant5.png">
            <div>
              <img src="@/assets/RestayranIcon4.png">
              <div>外卖店</div>
            </div>
          </div>
          <div class="content1_body_item">
            <img src="@/assets/Restaurant6.png">
            <div>
              <img src="@/assets/RestayranIcon5.png">
              <div>咖啡厅</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="centerBox content2">
      <div class="title">
        收银台点餐
        <div></div>
      </div>
      <div class="content_body">
        <img src="@/assets/Restaurant7.png">
        <div>
          <div>
            <div class="subtitle">操作简单</div>
            <div class="textDetail">操作简单上手即会，无需培训，提升收银点餐效率，减少客户等待时间</div>
          </div>
          <div>
            <div class="subtitle">大气时尚</div>
            <div class="textDetail">最新触摸屏设备，大气时尚，众多门店的选择</div>
          </div>
          <div>
            <div class="subtitle">全天售后保障</div>
            <div class="textDetail">7*24小时售后支持，覆盖全球各个时区，确保您随时获得帮助</div>
          </div>
        </div>
      </div>
    </div>
    <div class="langBox">
      <div class="centerBox content3">
        <div class="title">
          服务员当面点餐
          <div></div>
        </div>
        <div class="content_body">
          <div>
            <div>
              <div class="subtitle">手持设备，提升就餐体验</div>
              <div class="textDetail">服务员使用手持设备点餐，就餐过程体验感更好，顾客更满意</div>
            </div>
            <div>
              <div class="subtitle">精细管理，大堂忙而不乱</div>
              <div class="textDetail">服务员、收银员、后厨在系统的调度下各司其职，业务虽忙，但忙而不乱，顾客被照顾的更舒适</div>
            </div>
            <div>
              <div class="subtitle">实时同步，多设备信息一致</div>
              <div class="textDetail">7*任何人接待的顾客，只要录入系统，桌台信息实时同步到所有设备，客户宾至如归 </div>
            </div>
          </div>
          <img src="@/assets/Restaurant8.png">
        </div>

      </div>
    </div>
    <div class="centerBox content4">
      <div class="title">
        顾客自助点餐
        <div></div>
      </div>
      <div class="content_body">
        <img src="@/assets/Restaurant9.png">
        <div>
          <div>
            <div class="subtitle">自助点餐，减少人力成本</div>
            <div class="textDetail">顾客自助点餐，店里就能减少大量的人力开销，同时还能提升点餐效率，提升坪效</div>
          </div>
          <div>
            <div class="subtitle">随时点餐，顾客不用等待</div>
            <div class="textDetail">顾客到店即可扫码点餐，不用排队等待，选餐过程也自由随心，减少人力成本的同时，就餐体验反而更好了</div>
          </div>
          <div>
            <div class="subtitle">多种模式，适合各种业态</div>
            <div class="textDetail">7*无论是有固定餐桌的正餐馆，还是无固定餐桌的快餐店，甚至没有餐桌的奶茶店，都有对应的模式支持 </div>
          </div>
        </div>
      </div>
    </div>
    <div class="langBox">
      <div class="centerBox content5">
        <div class="title">
          不止收银点餐
          <div></div>
        </div>
        <div class="content5_body">
          <img src="@/assets/Restaurant10.png"
            class="computer">
          <div class="content5_body_bubble content5_body_topBubble">
            <div class="content5_body_item"
              left>
              <img src="@/assets/Restaurant11.png">
              <div>
                <div class="content5_body_item_subtitle">桌台管理</div>
                <div class="content5_body_item_text">实时管理餐桌状态，提高翻台率</div>
              </div>
            </div>
            <div class="content5_body_item"
              right>
              <div>
                <div class="content5_body_item_subtitle">订单管理</div>
                <div class="content5_body_item_text">从点餐到收银，每笔订单一清二楚</div>
              </div>
              <img src="@/assets/Restaurant12.png">
            </div>
          </div>
          <div class="content5_body_bubble content5_body_middleBubble">
            <div class="content5_body_item"
              left>
              <img src="@/assets/Restaurant13.png">
              <div>
                <div class="content5_body_item_subtitle">菜品管理</div>
                <div class="content5_body_item_text">支持各种菜品搭配，无论您经营什么业态</div>
              </div>
            </div>
            <div class="content5_body_item"
              right>
              <div>
                <div class="content5_body_item_subtitle">后厨管理</div>
                <div class="content5_body_item_text">与前台实时联动，提升后厨效率，减少客户等待</div>
              </div>
              <img src="@/assets/Restaurant14.png">
            </div>
          </div>
          <div class="content5_body_bubble content5_body_bottomBubble">
            <div class="content5_body_item"
              left>
              <img src="@/assets/Restaurant15.png">
              <div>
                <div class="content5_body_item_subtitle">营销工具</div>
                <div class="content5_body_item_text">多种营销工具帮助您占领市场</div>
              </div>
            </div>
            <div class="content5_body_item"
              right>
              <div>
                <div class="content5_body_item_subtitle">智慧经营</div>
                <div class="content5_body_item_text">老板报表，实时向您汇报经营状态</div>
              </div>
              <img src="@/assets/Restaurant16.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <bottomBar></bottomBar>
    <tryUseDialog :show="show"
      @close="show = false"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import tryUseDialog from '@/components/tryUseDialog'
import bottomBar from '@/components/bottomBar'
export default {
  name: '',
  props: {},
  components: {
    bottomBar,
    tryUseDialog
  },
  data() {
    return {
      show: false
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toUs() {
      this.$router.push('/aboutUs')
    }
  }
}
</script>

<style scoped lang="scss">
.phone {
  display: none;
}
.banner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    vertical-align: top;
  }
  .banner_text {
    position: absolute;
    top: 46%;
    left: 191px;
    .banner_title {
      margin-bottom: 11px;
      font-size: 29px;
      font-weight: 500;
      color: #092e53;
      line-height: 40px;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
    .banner_subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #092e53;
      line-height: 27px;
      letter-spacing: 1px;
      margin-bottom: 30px;
    }
    .tryUse {
      width: 120px;
      height: 43px;
      background: #2e93fe;
      border: 1px solid #2b9cf9;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 43px;
      letter-spacing: 1px;
      text-align: center;
    }
  }
}

.centerBox {
  width: 1200px;
  margin: 0 auto;
}
.langBox {
  background: #ebf4ff;
}
.content1 {
  padding: 19px 0 28px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 240px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }
  .content1_body {
    display: flex;
    margin-top: 28px;
    .content1_body_item {
      position: relative;
      width: 240px;
      height: 240px;
      > img {
        width: 100%;
      }
      > div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: white;
        font-size: 19px;
        font-weight: 600;
        color: #ffffff;
        line-height: 21px;
        > img {
          width: 45px;
          height: 45px;
          margin-bottom: 11px;
        }
      }
    }
  }
}
.content2,
.content3,
.content4 {
  padding: 19px 0 28px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 240px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }

  > .content_body {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    img {
      width: 359px;
    }
    > div {
      width: 794px;
      box-shadow: 0px 2px 8px 0px #ceddf1;
      background: white;
      padding: 30px 60px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .subtitle {
        font-size: 19px;
        font-weight: 500;
        color: #272832;
        line-height: 22px;
        letter-spacing: 1px;
        margin-bottom: 2px;
      }
      .textDetail {
        font-size: 14px;
        font-weight: 400;
        color: #828a98;
        line-height: 22px;
      }
    }
  }
}
.content5 {
  padding: 32px 0 61px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 240px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }
  .content5_body {
    position: relative;
    height: 422px;
    margin-top: 78px;
    text-align: center;
    img {
      width: 662px;
    }
    .content5_body_bubble {
      height: 104px;
      position: absolute;
      left: 0;
      width: 100%;
      // background: black;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content5_body_bottomBubble,
    .content5_body_topBubble {
      padding: 0 106px;
    }
    .content5_body_topBubble {
      top: 0;
    }
    .content5_body_middleBubble {
      top: calc(50% - 52px);
    }
    .content5_body_bottomBubble {
      bottom: 0;
    }

    .content5_body_item {
      width: 407px;
      height: 104px;
      background: #ffffff;
      box-shadow: 0px 2px 2px 0px #32c5ff;
      display: flex;
      align-items: center;
      padding: 20px 24px;
      img {
        width: 64px;
        height: 64px;
      }
    }
    .content5_body_item[left],
    .content5_body_item[right] {
      text-align: left;
      img {
        margin-right: 15px;
      }
      .content5_body_item_subtitle {
        font-size: 17px;
        font-weight: 600;
        color: #333333;
        line-height: 21px;
        margin-bottom: 8px;
      }
      .content5_body_item_text {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
      }
    }
    .content5_body_item[right] {
      justify-content: right;
      text-align: right;
      img {
        margin-left: 15px;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .computer {
    display: none;
  }
  .phone {
    display: block;
  }
  .centerBox {
    width: auto;
  }
  .content1 {
    .content1_body {
      flex-wrap: wrap;
      justify-content: center;
      .content1_body_item {
        margin-bottom: 10px;
      }
    }
  }
  .content2,
  .content3,
  .content4 {
    > .content_body {
      flex-wrap: wrap;
      img {
        width: 100%;
      }
    }
  }
  .content5 {
    background: white;
    padding-bottom: 0;
    .content5_body {
      height: auto;
      .content5_body_bubble {
        height: auto;
        width: 100%;
        flex-wrap: wrap;
        padding: 0;
        position: static;
        .content5_body_item {
          width: 100%;
        }
      }
    }
  }
}
</style>
