<template>
  <div class="ptuapp"
    @click="changeNum++">
    <div class="banner">
      <img src="@/assets/Ptuapp1.png"
        class="computer">
      <img src="@/assets/Ptuapp1Phone.png"
        class="phone">
      <div class="computer">
        客户网上点货，生意不再守株待兔<br>
        私域流量，促成重复购买
      </div>
    </div>
    <div class="langbox">
      <div class="centerBox content1">
        <div class="title">
          客户价值
        </div>
        <div class="content1_body">
          <div class="content1_body_item">
            <img src="@/assets/Ptuapp2.png"
              alt="">
            <div class="content1_body_item_subtitle">不必到店，随时点货</div>
            <div class="content1_body_item_detail">客户不必到店里，在网上随时点货，有手机或电脑就可以交易</div>
          </div>
          <div class="content1_body_item">
            <img src="@/assets/Ptuapp3.png"
              alt="">
            <div class="content1_body_item_subtitle">订单反馈，产品同步</div>
            <div class="content1_body_item_detail">客户通过批兔兔购买商品，实时反馈订单进度，通过扫码入库，一键将产品导入客户系统</div>
          </div>
          <div class="content1_body_item">
            <img src="@/assets/Ptuapp4.png"
              alt="">
            <div class="content1_body_item_subtitle">重复购买，一键完成</div>
            <div class="content1_body_item_detail">客户需要再次购买时，选择“再来一单”，一键完成重复购买。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="centerBox content2">
      <div class="title">
        业务模式
      </div>
      <div class="content2_body">
        <div class="content2_body_item">
          <img src="@/assets/Ptuapp5.png">
          <div>
            <div class="content2_body_item_subtitle">模式一: 身份审核，一客一价</div>
            <div class="content2_body_item_detail">该模式下，客户需要通过身份审核，才能查看产品资料和报价，甚至能做到一客一价，精细化管理
              不同的销售渠道。</div>
          </div>
        </div>
        <div class="content2_body_item">
          <div class="colorBox">
            <div class="content2_body_item_subtitle">模式二:开放经营，价格公开</div>
            <div class="content2_body_item_detail">该模式下，将产品公开到网络商城，客户自行选购，就像在逛亚马逊</div>
          </div>
          <img src="@/assets/Ptuapp6.png">
        </div>
      </div>
    </div>
    <div class="centerBox content3">
      <div class="title">
        经营模式
      </div>
      <div class="content3_body">
        <div class="content3_body_item">
          <div class="content3_body_item_text">
            <div class="content3_body_item_subtitle">
              分组价格
              <div></div>
            </div>
            <div class="content3_body_item_detail">4个价格分组，不同渠道、不同买家，采用不同的价格</div>
          </div>
          <img class="content3_body_item_bg"
            src="@/assets/Ptuapp7.png">
        </div>
        <div class="content3_body_item">
          <div class="content3_body_item_text">
            <div class="content3_body_item_subtitle">
              阶梯价格
              <div></div>
            </div>
            <div class="content3_body_item_detail">根据购买量不同，采用不同的销售价格，买的越多，价格越便宜</div>
          </div>
          <img class="content3_body_item_bg"
            src="@/assets/Ptuapp8.png">
        </div>
        <div class="content3_body_item">
          <div class="content3_body_item_text">
            <div class="content3_body_item_subtitle">
              多币种
              <div></div>
            </div>
            <div class="content3_body_item_detail">同一地区，同时支持3个结算币种，既可以按汇率换算，也可以按币种定价</div>
          </div>
          <img class="content3_body_item_bg"
            src="@/assets/Ptuapp9.png">
        </div>
        <div class="content3_body_item">
          <div class="content3_body_item_text">
            <div class="content3_body_item_subtitle">
              先货后款
              <div></div>
            </div>
            <div class="content3_body_item_detail">支持赊销，根据账期结算货款，同时自动记录客户账目</div>
          </div>
          <img class="content3_body_item_bg"
            src="@/assets/Ptuapp10.png">
        </div>
        <div class="content3_body_item">
          <div class="content3_body_item_text">
            <div class="content3_body_item_subtitle">
              预付货款
              <div></div>
            </div>
            <div class="content3_body_item_detail">支持客户预存货款，根据预存款拿货，同时自动记录客户账目</div>
          </div>
          <img class="content3_body_item_bg"
            src="@/assets/Ptuapp11.png">
        </div>
      </div>
    </div>
    <div class="langbox">
      <div class="centerBox content4">
        <div class="title">
          线上线下一体管理
        </div>
        <div class="content4_body">
          <img src="@/assets/Ptuapp12.png">
          <div class="content4_textBody">
            <div>
              <div class="content4_textBody_subtitle">共享库存，减少囤货成本</div>
              <div class="content4_textBody_textdetail">网上商城和实体店共享库存管理，一处库存两处售卖，减少囤货成本</div>
            </div>
            <div>
              <div class="content4_textBody_subtitle">订单集中，数据统一管理</div>
              <div class="content4_textBody_textdetail">网上商城产生的订单和实体店产生的订单集中管理，既可以分开核算，又可以集中统计</div>
            </div>
            <div>
              <div class="content4_textBody_subtitle">账目统一，线上线下一致</div>
              <div class="content4_textBody_textdetail">同一位客户，无论是在网上商城还是实体店，都有统一的往来账目，不因渠道不同而混淆</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomBar></bottomBar>
    <tryUseDialog :show="show"
      @close="show = false"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import sideBar from '@/components/sideBar'
import tryUseDialog from '@/components/tryUseDialog'
import bottomBar from '@/components/bottomBar'
import product from '@/components/product'
export default {
  name: 'ptuapp',
  props: {},
  components: {
    bottomBar,
    product,
    tryUseDialog,
    sideBar
  },
  data() {
    return {
      show: false,
      changeNum: 0
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toUs() {
      this.$router.push('/aboutUs')
    }
  }
}
</script>

<style scoped lang="scss">
.banner {
  position: relative;
  img {
    width: 100%;
  }
  div {
    position: absolute;
    bottom: 74px;
    font-size: 29px;
    font-weight: 500;
    color: #ffffff;
    line-height: 54px;
    text-align: center;
    width: 100%;
    // margin: 0 auto;
  }
}
.langbox {
  width: 100%;
  background: #f8fcff;
}
.centerBox {
  width: 1200px;
  margin: 0 auto;
  // display: flex;
}
.content1 {
  padding: 74px 0 78px 0;
  display: block;
  .title {
    width: 242px;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    border-bottom: 5px #2e93fe solid;
    padding-bottom: 13px;
    text-align: center;
    margin: 0 auto;
  }
  .content1_body {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .content1_body_item {
      text-align: center;
      img {
        width: 134px;
        height: 134px;
      }
      .content1_body_item_subtitle {
        margin-top: 20px;
        margin-bottom: 12px;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
        letter-spacing: 1px;
      }
      .content1_body_item_detail {
        width: 245px;
        height: 82px;
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        line-height: 27px;
        letter-spacing: 1px;
      }
    }
  }
}
.content2 {
  padding-top: 72px;
  .title {
    width: 242px;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    border-bottom: 5px #2e93fe solid;
    padding-bottom: 13px;
    text-align: center;
    margin: 0 auto;
  }
  .content2_body {
    margin-top: 40px;
    .content2_body_item {
      box-shadow: 0px 2px 8px 0px #ceddf1;
      display: flex;
      img {
        width: 392px;
        height: 216px;
      }
      > div {
        flex: 1;
        padding: 48px 40px;
      }
      &:last-child {
        text-align: right;
        background: linear-gradient(141deg, #f8fcff 0%, #f1f6fb 100%);
        border-bottom-right-radius: 10px;
      }
      .content2_body_item_subtitle {
        font-weight: bolder;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
        letter-spacing: 1px;
        margin-bottom: 14px;
      }
      .content2_body_item_detail {
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        line-height: 27px;
        letter-spacing: 1px;
      }
    }
  }
}
.content3 {
  padding: 72px 0;
  .title {
    width: 242px;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    border-bottom: 5px #2e93fe solid;
    padding-bottom: 13px;
    text-align: center;
    margin: 0 auto;
  }
  .content3_body {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .content3_body_item {
      position: relative;
      width: 19%;
      .content3_body_item_text {
        position: absolute;
        padding: 32px 24px;
        .content3_body_item_subtitle {
          margin-bottom: 28px;
          font-size: 19px;
          font-weight: 500;
          color: #272832;
          line-height: 26px;
          letter-spacing: 1px;
          > div {
            margin-top: 12px;
            height: 4px;
            width: 40px;
            background: #2e93fe;
          }
        }
        .content3_body_item_detail {
          font-size: 14px;
          font-weight: 400;
          color: #828a98;
          line-height: 22px;
        }
      }
      .content3_body_item_bg {
        width: 220px;
      }
    }
  }
}
.content4 {
  padding: 72px 0 62px 0;
  .title {
    width: 242px;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    border-bottom: 5px #2e93fe solid;
    padding-bottom: 13px;
    text-align: center;
    margin: 0 auto;
  }
  .content4_body {
    align-items: center;
    margin-top: 30px;
    display: flex;
    img {
      width: 505px;
      height: 311px;
      margin-right: 26px;
    }
    .content4_textBody {
      flex: 1;
      height: 292px;
      box-shadow: 0px 2px 8px 0px #ceddf1;
      background: white;
      padding: 24px 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .content4_textBody_subtitle {
        font-size: 19px;
        font-weight: 500;
        color: #272832;
        line-height: 26px;
        letter-spacing: 1px;
      }
      .content4_textBody_textdetail {
        font-size: 14px;
        font-weight: 400;
        color: #828a98;
        line-height: 22px;
      }
    }
  }
}

.phone {
  display: none;
}
@media screen and (max-width: 768px) {
  .computer {
    display: none;
  }
  .phone {
    display: block;
  }
  .centerBox {
    width: auto;
  }

  .content1 {
    .content1_body {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .content2 {
    .content2_body {
      .content2_body_item {
        flex-wrap: wrap;
        text-align: left;
        img {
          margin: 0 auto;
        }
        .colorBox {
          text-align: left;
        }
      }
    }
  }
  .content3 {
    padding-bottom: 0;
    .content3_body {
      flex-wrap: wrap;
      justify-content: center;
      .content3_body_item {
        width: 49%;
        .content3_body_item_bg {
          width: 100%;
        }
      }
    }
  }
  .content4 {
    .content4_body {
      flex-wrap: wrap;
      img {
        width: 100%;
        height: auto;
        margin-right: 0;
      }
      .content4_textBody {
        width: 100%;
      }
    }
  }
}
</style>
