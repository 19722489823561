<template>
  <div class="appDownload"
    @click="openIndex = -1">
    <div class="bacnner">
      <div class="center">
        <div class="left">
          <div class="title">TOOERP生态系统</div>
          <div class="intro">
            随时、随地、随场景，全方位助力华商生意
          </div>
          <div class="bannerBtn hoverBtn"
            @click="show = true">免费试用</div>
        </div>
        <img src="@/assets/downloadbanner.png">
        <div class="left phone">
          <div class="title">TOOERP生态系统</div>
          <div class="intro">
            随时、随地、随场景，全方位助力华商生意
          </div>
          <div class="bannerBtn"
            @click="show = true">免费试用</div>
        </div>
      </div>
    </div>
    <div class="down">
      <!-- <div class="title">移动端产品</div>
      <div class="title2">基于全域消费者覆盖，以高效人群运营、创新广告丰富场景营销，主力品牌完成营销目标</div> -->
      <div class="appList">
        <div class="appItem"
          v-for="(item,index) in appList"
          :key="index">
          <img :src="item.logo"
            class="logo">
          <div class="appName">{{item.name}}</div>
          <div class="appTip">
            {{item.tip}}
          </div>
          <a v-if="item.andUrl"
            :href="item.andUrl">
            <div class="btn1">
              <div>
                <img src="@/assets/downIconAnd.png">
                安卓下载
              </div>
              <img src="@/assets/downIconBlue.png">
            </div>
          </a>
          <a v-if="item.iosUrl"
            :href="item.iosUrl">
            <div class="btn1 btn2">
              <div>
                <img src="@/assets/downIconIos.png">
                苹果下载
              </div>
              <img src="@/assets/sideIcon3action.png">
            </div>
          </a>

          <div class="codeBox"
            v-if="item.andUrl || item.iosUrl"
            @click.stop="openIndex = index">
            <img src="@/assets/codeIcon.png">
            <span class="codeText">二维码下载</span>
          </div>

          <div class="downCode"
            :class="openIndex === index && 'downCodeOpen'">
            <div>
              <div>
                <img :src="item.codeImg">
                <div>{{item.codeText}}</div>
              </div>
            </div>
          </div>
          <div v-if="item.wechart"
            class="wechartCode">
            <img :src="item.codeImg">
            <div>{{item.codeText}}</div>
          </div>
        </div>
        <!-- <div class="appItem">
          <img src="@/assets/app1.png"
            class="logo">
          <div class="appName">PTU2商家端</div>
          <div class="appTip">
            移动端销售管理，一部手机，数据报表实时查看，不用亲力亲为，老板运筹千里之外
          </div>
          <div class="btn1">
            <div>
              <img src="@/assets/downIconAnd.png">
              安卓下载
            </div>
            <img src="@/assets/downIconBlue.png">
          </div>
          <div class="btn1 btn2">
            <div>
              <img src="@/assets/downIconIos.png">
              苹果下载
            </div>
            <img src="@/assets/sideIcon3action.png">
          </div>
          <div class="codeBox">
            <img src="@/assets/codeIcon.png">
            二维码下载
          </div>
        </div>
        <div class="appItem">
          <img src="@/assets/app1.png"
            class="logo">
          <div class="appName">PDA商家版</div>
          <div class="appTip centerAlign">
            智能扫描新视界
          </div>
          <div class="btn1">
            <div>
              <img src="@/assets/downIconAnd.png">
              安卓下载
            </div>
            <img src="@/assets/downIconBlue.png">
          </div>
          <div class="btn1 btn2">
            <div>
              <img src="@/assets/downIconIos.png">
              苹果下载
            </div>
            <img src="@/assets/sideIcon3action.png">
          </div>
          <div class="codeBox">
            <img src="@/assets/codeIcon.png">
            二维码下载
          </div>
        </div>
        <div class="appItem">
          <img src="@/assets/app2.png"
            class="logo">
          <div class="appName">兔子收银</div>
          <div class="appTip centerAlign">
            多语言 多货币 更简洁
          </div>
          <div class="btn1">
            <div>
              <img src="@/assets/downIconAnd.png">
              安卓下载
            </div>
            <img src="@/assets/downIconBlue.png">
          </div>
          <div class="btn1 btn2">
            <div>
              <img src="@/assets/downIconIos.png">
              苹果下载
            </div>
            <img src="@/assets/sideIcon3action.png">
          </div>
          <div class="codeBox">
            <img src="@/assets/codeIcon.png">
            二维码下载
          </div>
        </div>
        <div class="appItem">
          <img src="@/assets/app7.png"
            class="logo">
          <div class="appName">提价器APP</div>
          <div class="appTip centerAlign">
            更专业，更便捷
          </div>
          <div class="btn1">
            <div>
              <img src="@/assets/downIconAnd.png">
              安卓下载
            </div>
            <img src="@/assets/downIconBlue.png">
          </div>
          <div class="btn1 btn2">
            <div>
              <img src="@/assets/downIconIos.png">
              苹果下载
            </div>
            <img src="@/assets/sideIcon3action.png">
          </div>
          <div class="codeBox">
            <img src="@/assets/codeIcon.png">
            二维码下载
          </div>
        </div>
        <div class="appItem">
          <img src="@/assets/app5.png"
            class="logo">
          <div class="appName">云点云店小程序</div>
          <div class="appTip">
            微信生态下的爆单利器，关联系统，轻松操作，产品图片分享，功能简洁，操作方便
          </div>
          <div class="btn1">
            <div>
              <img src="@/assets/downIconAnd.png">
              安卓下载
            </div>
            <img src="@/assets/downIconBlue.png">
          </div>
          <div class="btn1 btn2">
            <div>
              <img src="@/assets/downIconIos.png">
              苹果下载
            </div>
            <img src="@/assets/sideIcon3action.png">
          </div>
          <div class="codeBox">
            <img src="@/assets/codeIcon.png">
            二维码下载
          </div>
        </div>-->
      </div>
    </div>
    <bottomBar></bottomBar>

    <tryUseDialog :show="show"
      @close="show = false"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import bottomBar from '@/components/bottomBar'
import tryUseDialog from '@/components/tryUseDialog'
export default {
  name: '',
  props: {},
  components: {
    tryUseDialog,
    bottomBar
  },
  data() {
    return {
      show: false,
      appList: [
        {
          logo: require('@/assets/app1.png'),
          name: 'PTU2商家端',
          tip: '不在店里，手机也能做生意，经营情况随时查看',
          andUrl: 'https://k2049.com/download/pda_v2.apk',
          iosUrl: 'https://apps.apple.com/app/id6444035139',
          codeImg: require('@/assets/downAppCode1.png'),
          codeText: 'PTU2商家版二维码'
        },
        {
          logo: require('@/assets/app4.png'),
          name: 'PTU2客户端',
          tip: '客户不到店里一样下单，生意从此不再守株待兔',
          andUrl: 'https://ptu2.com/download/ptu2_v2.apk',
          iosUrl: 'https://itunes.apple.com/app/id1435773691',
          codeImg: require('@/assets/downAppCode2.png'),
          codeText: 'PTU2买家版二维码'
        },
        {
          logo: require('@/assets/app6.png'),
          name: 'PTU2推销员版',
          tip: '推销员随身携带产品资料，随时、随地提升业绩，查看销售提成',
          tipCenter: true,
          andUrl: 'https://k2049.com/download/Ksales.apk',
          iosUrl: 'https://apps.apple.com/app/id6448233246',
          codeImg: require('@/assets/downAppCode6.png'),
          codeText: 'PDA商家版二维码'
        },
        {
          logo: require('@/assets/app2.png'),
          name: '兔子收银',
          tip: '本地雇员更习惯、华商老板更明白的收银系统',
          tipCenter: true,
          andUrl: 'https://k2049.com/download/pos_v2.apk',
          iosUrl: '',
          codeImg: require('@/assets/downAppCode3.png'),
          codeText: '兔子收银二维码'
        },
        {
          logo: require('@/assets/app7.png'),
          name: '提价器APP',
          tip: '更符合本地客户习惯的展示方式',
          tipCenter: true,
          andUrl: 'https://ptu2.com/download/scan_v2.apk',
          iosUrl: '',
          codeImg: require('@/assets/downAppCode7.png'),
          codeText: '提价器APP二维码'
        },
        {
          logo: require('@/assets/app5.png'),
          name: '云点云店小程序',
          tip: '可以社交传播的爆单利器，有微信的地方一个二维码就能做生意',
          andUrl: '',
          iosUrl: '',
          codeImg: require('@/assets/downAppCode4.png'),
          wechart: true,
          codeText: '云点云店小程序二维码'
        }
      ],
      openIndex: -1
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toUs() {
      this.$router.push('/aboutUs')
    }
  }
}
</script>

<style scoped lang="scss">
.bacnner {
  background: linear-gradient(to top, #eef6fd, #ffffff);
  .center {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 36px;
      font-weight: 500;
      color: #092e53;
      line-height: 50px;
      letter-spacing: 1px;
    }
    .intro {
      margin-top: 13px;
      font-size: 20px;
      font-weight: 400;
      color: #092e53;
      line-height: 34px;
      letter-spacing: 1px;
    }
    .bannerBtn {
      width: 150px;
      height: 54px;
      background: #2e93fe;
      border-radius: 8px;
      border: 1px solid #2b9cf9;
      margin-top: 21px;
      color: white;
      text-align: center;
      line-height: 54px;
    }
    > img {
      width: 50%;
    }
  }
}
.down {
  padding-top: 69px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}
.title {
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 42px;
  letter-spacing: 1px;
  margin-bottom: 11px;
}
.title2 {
  font-size: 20px;
  font-weight: 400;
  color: #555555;
  line-height: 42px;
  letter-spacing: 1px;
  margin-bottom: 68px;
}

.appList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  > div {
    width: 30%;
    height: 540px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #ceddf1;
    border-radius: 16px;
    margin-bottom: 70px;
  }
}

.appItem {
  position: relative;
  text-align: center;
  padding-top: 38px;
  .logo {
    width: 76px;
    height: 76px;
    margin-bottom: 23px;
  }
  .appName {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
    letter-spacing: 1px;
    margin-bottom: 26px;
  }
  .appTip {
    width: 80%;
    margin: 0 auto;
    height: 100px;
    font-size: 16px;
    font-weight: 400;
    color: #555555;
    line-height: 26px;
    text-align: left;
  }
  .centerAlign {
    text-align: center;
  }
  a {
    text-decoration: none;
  }
  .btn1 {
    width: 80%;
    height: 54px;
    background: #eaf5ff;
    border-radius: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    > div {
      font-size: 18px;
      font-weight: 500;
      color: #2e93fe;
      line-height: 25px;
      display: flex;
      align-items: center;
      > img {
        margin-right: 15px;
      }
    }
    img {
      width: 34px;
      height: 34px;
    }
    &:hover {
      background: #dbebfd;
    }
  }
  .btn2 {
    margin-top: 20px;
    background: #2e93fe;
    > div {
      color: white;
    }
    &:hover {
      background: #40a9ff;
    }
  }

  .downCode {
    display: none;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #092e53, $alpha: 0.4);
    width: 100%;
    height: 540px;
    > div {
      width: 236px;
      height: 274px;
      background: #ffffff;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 198px;
        height: 198px;
      }
    }
  }
  .downCodeOpen {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.codeBox {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  img {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }
  .codeText {
    cursor: pointer;
  }
}
.wechartCode {
  img {
    width: 180px;
    height: 180px;
    margin-bottom: 10px;
  }
}

.phone {
  display: none;
}
@media screen and (max-width: 768px) {
  .bacnner {
    .center {
      padding-bottom: 30px;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      .left {
        display: none;
      }
      img {
        width: 100%;
      }
      .phone {
        padding: 1rem;
        display: block;
        .title {
          font-size: 2.4rem;
        }
        .intro {
          font-size: 1.4rem;
          width: 100%;
          height: auto;
          line-height: 20px;
        }
        .bannerBtn {
          margin: 0 auto;
          margin-top: 2rem;
        }
      }
    }
  }
  .down {
    width: 100%;
    padding: 1rem;
    .title {
      margin-bottom: 1rem;
      font-size: 2.4rem;
    }
    .title2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }
  .appList {
    flex-wrap: wrap;
    justify-content: center;
    > div {
      margin: 0;
      width: 96%;
      margin-bottom: 2rem;
    }
  }
}
</style>
