<template>
  <div class="hardware">
    <div class="title">配套硬件</div>
    <div class="productList">
      <div class="scrollBox"
        :style="{left:left+'px'}"
        @mouseover="clear"
        @mouseleave="scrollBox">
        <div class="productItem">
          <img src="@/assets/product1.png">
          <div>收银POS机</div>
        </div>
        <div class="productItem">
          <img src="@/assets/product2.png">
          <div>小票机</div>
        </div>
        <div class="productItem">
          <img src="@/assets/product3.png">
          <div>标签打印机</div>
        </div>
        <div class="productItem">
          <img src="@/assets/product5.png">
          <div>手持机</div>
        </div>
        <div class="productItem">
          <img src="@/assets/product6.png">
          <div>扫描平台</div>
        </div>
        <div class="productItem">
          <img src="@/assets/product7.png">
          <div>扫码一体机</div>
        </div>
        <div class="productItem">
          <img src="@/assets/product8.png">
          <div>提价器</div>
        </div>
        <div class="productItem">
          <img src="@/assets/product9.png">
          <div>手持机</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      left: 0,
      scrollType: 'left',
      timer: null
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.scrollBox()
  },
  methods: {
    scrollBox() {
      this.timer = setInterval(() => {
        if (this.left >= 60) {
          this.scrollType = 'left'
        }
        if (this.left == -1460) {
          this.scrollType = 'right'
        }
        if (this.scrollType == 'left') {
          this.left -= 2
        } else {
          this.left += 2
        }
      }, 10)
    },
    clear() {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped lang="scss">
.hardware {
  width: 90%;
  padding: 133px 0;
  margin: 0 auto;
  text-align: center;
  .title {
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }
  .productList {
    margin-top: 54px;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    height: 330px;

    .scrollBox {
      display: flex;
      align-items: center;
      position: absolute;
      height: 330px;
      left: 20px;
    }
  }
  .productItem {
    margin-right: 40px;
    width: 310px;
    height: 310px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #ceddf1;
    border-radius: 16px;
    text-align: center;
    padding-top: 43px;
    img {
      width: 154px;
      height: 154px;
      margin-bottom: 38px;
    }
  }
}
@media screen and (max-width: 768px) {
  .hardware {
    display: none;
  }
}
</style>
