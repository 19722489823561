<template>
  <div class="wholesale">
    <div class="banner">
      <div class="center">
        <div class="leftText">
          <div class="title">批发系统</div>
          <div class="intro">
            批发系统 免费试用<br> 不限国家地区、系统稳定、功能强大，场景深入
          </div>
          <div class="bannerBtn hoverBtn"
            @click="show = true">免费试用</div>
        </div>
        <img src="../assets/wholesaleBanner.png"
          class="bannerBg">
        <div class="leftText phone">
          <div class="title">批发系统</div>
          <div class="intro">
            批发系统 免费试用<br> 不限国家地区、系统稳定、功能强大，场景深入
          </div>
          <div class="bannerBtn"
            @click="show = true">免费试用</div>
        </div>
      </div>
      <img src="../assets/wholesaleBanner2.png"
        class="shadowLeft">
      <img src="../assets/wholesaleBanner2.png"
        class="shadowRight">
    </div>
    <div class="light">
      <div>
        <div class="title">协同ERP核心亮点 </div>
        <div class="lightList">
          <div class="lightItem">
            <img src="@/assets/wholesaleIcon1.png">
            <div class="text1">功能多</div>
            <div class="text2">
              销售管理系统共有
              <span>10</span>个<br>
              功能模块
            </div>
            <div class="lightDetail1">
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>主控</span>
              </div>

              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>货物</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>报表</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>客户</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>销售</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>财务</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>发票</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>连锁</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>分销</span>
              </div>
              <div>
                <img src="@/assets/wholesaleIcon5.png">
                <span>设置</span>
              </div>
            </div>
          </div>
          <div class="lightItem">
            <img src="@/assets/wholesaleIcon2.png">
            <div class="text1">系统稳</div>
            <div class="text2">
              <span>50+</span>海外顶尖服务器<br>
              供应商提供硬件支持
            </div>
            <div class="lightDetail2">
              <div>全球主干网络<br>速度快 稳定高效</div>

              <div>数据备份全球<br>自动数据备份</div>
            </div>
          </div>
          <div class="lightItem">
            <img src="@/assets/wholesaleIcon3.png">
            <div class="text1">服务好</div>
            <div class="text2">
              <span>20+</span>服务网点覆盖<br>
              全球
            </div>
            <div class="lightDetail3">
              <div><span>7x24h</span><br>速度快 稳定高效</div>
              <div><span>100+</span><br>自动数据备份</div>
            </div>
          </div>
          <div class="lightItem">
            <img src="@/assets/wholesaleIcon4.png">
            <div class="text1">成本省</div>
            <div class="text2">
              销售仓储运营环节
            </div>
            <div class="lightDetail2">
              <div>销售仓储运营环节<br>节约人工</div>
              <div>工作效率提高<br>店铺流量峰值不用担心</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content1">
      <img src="@/assets/wholesaleIcon6.png">
      <div>
        <div class="title">全生态产品 全方位保障</div>
        <div class="detail">
          云终端技术，支持PC、iPad、手机、PDA、多场景使用，<br>
          随时掌握运营实况，进行审批等操作。<br>
          定期检测漏洞，签署、严守保密协议<br>
          国家高新技术企业认定 为您的数据安全保驾护航。
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="center">
        <div class="left">
          <div class="title">销售/库存/财务管理 顶级配置 极速销售</div>
          <div class="detail">
            手持机扫描销售，更快、更准、更高效。<br>
            SaaS软件，无需下载即可使用，自研调度引擎，运行快速。<br>
            支持多货币混合结算，欧元、美金、比索、兹罗提账目更准确，轻松<br>
            省力。
          </div>
        </div>
        <img src="@/assets/wholesaleIcon7.png">
        <div class="phone">
          <div class="title">销售/库存/财务管理 顶级配置 极速销售</div>
          <div class="detail">
            手持机扫描销售，更快、更准、更高效。
            SaaS软件，无需下载即可使用，自研调度引擎，运行快速。
            支持多货币混合结算，欧元、美金、比索、兹罗提账目更准确，轻松
            省力。
          </div>
        </div>
      </div>

    </div>
    <div class="content1">
      <img src="@/assets/wholesaleIcon8.png">
      <div>
        <div class="title">最新触摸屏设备 大气时尚 门店必用</div>
        <div class="detail">
          批发零售使用无门槛<br>
          匈/波/罗/智/墨/德/西/保/法/斯，美洲，众多商户的选择
        </div>
      </div>
    </div>
    <div class="content2 content4">
      <div class="center">
        <div class="left">
          <div class="title">无限扩展商机 点货APP 掌心大市场</div>
          <div class="detail">
            让客户可以随时随地下单，生意不打烊！
          </div>
        </div>
        <img src="@/assets/wholesaleIcon9.png">
        <div class="phone">
          <div class="title">无限扩展商机 点货APP 掌心大市场</div>
          <div class="detail">
            让客户可以随时随地下单，生意不打烊！
          </div>
        </div>
      </div>
    </div>
    <div class="sale">
      <div class="title">TOOERP销售系统</div>
      <img src="@/assets/wholesaleIcon10.png">
    </div>
    <product></product>
    <bottomBar></bottomBar>
    <tryUseDialog :show="show"
      @close="show = false"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import tryUseDialog from '@/components/tryUseDialog'
import bottomBar from '@/components/bottomBar'
import product from '@/components/product'
export default {
  name: '',
  props: {},
  components: {
    bottomBar,
    product,
    tryUseDialog
  },
  data() {
    return {
      show: false
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toUs() {
      this.$router.push('/aboutUs')
    }
  }
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
  .shadowLeft {
    position: absolute;
    width: 300px;
    top: -100px;
    left: 0;
  }
  .shadowRight {
    transform: rotateY(180deg) rotateX(180deg);
    position: absolute;
    width: 300px;
    bottom: -100px;
    right: 0;
  }
  .center {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .bannerBg {
    width: 40%;
  }
  .leftText {
    .title {
      font-size: 36px;
      font-weight: 500;
      color: #092e53;
      line-height: 50px;
      letter-spacing: 1px;
    }
    .intro {
      margin-top: 13px;
      height: 68px;
      font-size: 20px;
      font-weight: 400;
      color: #092e53;
      line-height: 34px;
      letter-spacing: 1px;
    }
    .bannerBtn {
      width: 150px;
      height: 54px;
      background: #2e93fe;
      border-radius: 8px;
      border: 1px solid #2b9cf9;
      margin-top: 21px;
      color: white;
      text-align: center;
      line-height: 54px;
    }
  }
}

.light {
  background: #f8fcff;
  height: 948px;
  > div {
    width: 90%;
    margin: 0 auto;
    padding-top: 103px;
  }
  .title {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    height: 50px;
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    letter-spacing: 1px;
    padding-bottom: 11px;
    border-bottom: 6px #2e93fe solid;
    box-sizing: content-box;
  }
  .lightList {
    display: flex;
    margin-top: 98px;
    justify-content: space-between;
  }
  .lightItem {
    padding-top: 38px;
    text-align: center;
    width: 22%;
    height: 570px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #ceddf1;
    border-radius: 16px;
    > img {
      width: 78px;
      height: 78px;
      margin-bottom: 16px;
    }
    .text1 {
      font-size: 28px;
      font-weight: 500;
      color: #333333;
      line-height: 40px;
      letter-spacing: 1px;
      margin-bottom: 23px;
    }
    .text2 {
      height: 68px;
      font-size: 20px;
      font-weight: 400;
      color: #555555;
      line-height: 34px;
      letter-spacing: 1px;
      span {
        color: #2e93fe;
        font-size: 24px;
      }
    }
    .lightDetail1 {
      margin-top: 35px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      > div {
        display: flex;
        align-items: center;
        width: 35%;
        margin-bottom: 25px;
      }
      img {
        width: 26px;
        height: 26px;
        margin-right: 10px;
      }
    }
    .lightDetail2,
    .lightDetail3 {
      margin-top: 35px;
      padding-top: 25px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      letter-spacing: 1px;
      > div {
        span {
          color: #2e93fe;
          font-size: 24px;
        }
        &:first-child {
          margin-bottom: 44px;
        }
      }
    }
  }
}
.content1 {
  width: 90%;
  padding: 50px 21px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40%;
    margin-right: 5%;
  }
  .title {
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    letter-spacing: 1px;
    margin-bottom: 34px;
  }
  .detail {
    font-size: 20px;
    font-weight: 500;
    color: #555555;
    line-height: 34px;
    letter-spacing: 1px;
  }
}
.content2 {
  background: #f8fcff;
  .center {
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      height: 42px;
      font-size: 30px;
      font-weight: 500;
      color: #333333;
      line-height: 42px;
      letter-spacing: 1px;
      margin-bottom: 36px;
    }
    .detail {
      height: 142px;
      font-size: 20px;
      font-weight: 500;
      color: #555555;
      line-height: 34px;
      letter-spacing: 1px;
    }
    img {
      width: 40%;
    }
  }
}
.sale {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding-top: 106px;
  .title {
    font-size: 36px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #092e53;
    line-height: 50px;
    letter-spacing: 1px;
    margin-bottom: 23px;
  }
  img {
    width: 50%;
  }
}

.phone {
  display: none;
}
@media screen and (max-width: 768px) {
  .banner {
    .center {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      .leftText {
        display: none;
      }
      .bannerBg {
        width: 100%;
      }
      .phone {
        display: block;
        .title {
          font-size: 2.4rem;
        }
        .intro {
          font-size: 1.4rem;
          width: 100%;
          height: auto;
          line-height: 20px;
        }
        .bannerBtn {
          margin: 0 auto;
          margin-top: 2rem;
        }
      }
    }
  }
  .light {
    height: auto;
    > div {
      width: 100%;
      flex-wrap: wrap;
    }
    .lightList {
      margin-top: 40px;
      flex-wrap: wrap;
      justify-content: center;
      > div {
        width: 90%;
        margin-bottom: 20px;
      }
    }
  }
  .content1 {
    width: 100%;
    flex-wrap: wrap;
    img {
      width: 100%;
      height: auto;
      margin: 0;
    }
    .title {
      font-size: 2.4rem;
      margin-bottom: 20px;
    }
    .detail {
      font-size: 1.6rem;
    }
  }
  .content2 {
    .center {
      width: 100%;
      flex-wrap: wrap;
      padding-left: 0;
      padding: 1rem;
      .left {
        display: none;
      }
      img {
        width: 100%;
        height: auto;
      }
      .phone {
        display: block;
      }
      .title {
        width: auto;
        height: auto;
        font-size: 2.4rem;
        margin-bottom: 20px;
        text-align: center;
      }
      .detail {
        width: auto;
        height: auto;
        font-size: 1.6rem;
      }
    }
  }
  .content4 {
    .center {
      .left {
        display: none;
      }
      .phone {
        text-align: center;
      }
    }
  }
  .sale {
    width: 100%;
    padding-top: 30px;
    .title {
      font-size: 2.4rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
