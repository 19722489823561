export let points = [
  {
    name: "中国",
    // 点坐标
    coordinate: [0.495, 0.60],
    // 光晕
    halo: {
      show: true,
      color: "#FF0000",
      radius: 20,
    },
    text: {
      show: false
    },
  },
  {
    name: "欧洲",
    coordinate: [0.28, 0.39],
    text: {
      show: false
    },
  },
  {
    name: "英国",
    coordinate: [0.16, 0.38],
    text: {
      show: false
    },
  },
  {
    name: "中东",
    coordinate: [0.20, 0.63],
    text: {
      show: false
    },
  },
  {
    name: "非洲",
    coordinate: [0.13, 0.69],
    text: {
      show: false
    },
  },
  {
    name: "墨西哥",
    coordinate: [0.79, 0.63],
    text: {
      show: false
    },
  },
  {
    name: "南美",
    coordinate: [0.91, 0.80],
    text: {
      show: false
    },
  },
]