<template>
  <div class="retail">
    <div class="banner">
      <img src="@/assets/Retail1.png"
        class="computer">
      <img src="@/assets/Retail1Phone.png"
        class="phone">

      <div>
        专为华商业务痛点开发的进销存管理系统<br>助力华商经营海外业务
      </div>
    </div>
    <div class="centerBox content1">
      <div class="title">
        您需要的全部销售场景
        <div></div>
      </div>
      <div class="content1_body">
        <div class="content1_body_item">
          <img src="@/assets/Retail2.png">
          <div>
            <div class="content1_body_item_subtitle">POS机收银</div>
            <div class="content1_body_item_textDetail">摸屏设备，时尚大气，门店必用</div>
          </div>
        </div>
        <div class="content1_body_item">
          <img src="@/assets/Retail3.png">
          <div>
            <div class="content1_body_item_subtitle">自选式销售</div>
            <div class="content1_body_item_textDetail">仓储式销售，客户自选下单，需<br>要什么扫什么。</div>
          </div>
        </div>
        <div class="content1_body_item">
          <img src="@/assets/Retail4.png">
          <div>
            <div class="content1_body_item_subtitle">电脑端销售</div>
            <div class="content1_body_item_textDetail">无须专业设备，一台电脑就可以<br>销售</div>
          </div>
        </div>
        <div class="content1_body_item">
          <img src="@/assets/Retail5.png">
          <div>
            <div class="content1_body_item_subtitle">手机端销售</div>
            <div class="content1_body_item_textDetail">不在店里，手机也能做生意</div>
          </div>
        </div>
        <div class="content1_body_item">
          <img src="@/assets/Retail6.png">
          <div>
            <div class="content1_body_item_subtitle">网络商城销售</div>
            <div class="content1_body_item_textDetail">客户在网上一样下单，生意从此<br>不再守株待兔</div>
          </div>
        </div>
        <div class="content1_body_item">
          <img src="@/assets/Retail7.png">
          <div>
            <div class="content1_body_item_subtitle">上门推销</div>
            <div class="content1_body_item_textDetail">推销员随时，随地完成交易，查<br>看业绩提成</div>
          </div>
        </div>
      </div>
    </div>
    <div class="langBox">
      <div class="centerBox content2">
        <div class="title">
          您需要的大多数经营模式
          <div></div>
        </div>
        <div class="content2_body">
          <div>
            <img src="@/assets/Retail8.png">
            <div class="content2_body_centerBox">
              <div class="content2_body_centerBox_subtitle">分组价格</div>
              <div class="content2_body_centerBox_text">4个价格组，不同渠道，不同买家，采用不同的价格</div>
            </div>
          </div>
          <div>
            <img src="@/assets/Retail9.png">
            <div class="content2_body_centerBox">
              <div class="content2_body_centerBox_subtitle">阶梯价格</div>
              <div class="content2_body_centerBox_text">根据购买量不同，采用不同的销售价格买的越多，价格越便宜</div>
            </div>
          </div>
          <div>
            <img src="@/assets/Retail10.png">
            <div class="content2_body_centerBox">
              <div class="content2_body_centerBox_subtitle">多币种</div>
              <div class="content2_body_centerBox_text">同一地区，同时支持3个结算币种既可以按汇率换算，也可以按币种定价</div>
            </div>
          </div>
          <div>
            <img src="@/assets/Retail11.png">
            <div class="content2_body_centerBox">
              <div class="content2_body_centerBox_subtitle">先货后款</div>
              <div class="content2_body_centerBox_text">支持赊帐，根据账期结算货款同时自动记录客户账目</div>
            </div>
          </div>
          <div>
            <img src="@/assets/Retail12.png">
            <div class="content2_body_centerBox">
              <div class="content2_body_centerBox_subtitle">预付货款</div>
              <div class="content2_body_centerBox_text">支持客户预存货款，根据预存货款拿货,同时自动记录客户账目</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="centerBox content3">
      <div class="title">
        帮您记录来往帐目
        <div></div>
      </div>
      <div class="content3_body">
        <div class="content3_body_item">
          <img src="@/assets/Retail13.png">
          <div class="content3_body_item_subtitle">供应商账目</div>
          <div class="content3_body_item_text">供应商往来账目，与每单采购一一对应每笔进货都可追溯</div>
        </div>
        <div class="content3_body_item">
          <img src="@/assets/Retail14.png">
          <div class="content3_body_item_subtitle">客户账目</div>
          <div class="content3_body_item_text">客户欠款，预付货款，交易，退货每笔往来一清二楚</div>
        </div>
        <div class="content3_body_item">
          <img src="@/assets/Retail15.png">
          <div class="content3_body_item_subtitle">财务流水</div>
          <div class="content3_body_item_text">全店每笔交易、每种结算方式，都可追溯钱货一致</div>
        </div>
      </div>
    </div>
    <div class="langBox">
      <div class="centerBox content4">
        <div class="title">
          帮您管理仓库存货
          <div></div>
        </div>
        <div class="content4_body">
          <div>
            <img src="@/assets/Retail16.png">
            <div>
              <div class="content4_body_subtitle">采购入库</div>
              <div class="content4_body_text">从货柜到仓库，从供应商到库存，从入库到保质期，全面追踪每单货物的来源</div>
            </div>
          </div>
          <div>
            <div>
              <div class="content4_body_subtitle">仓库管理</div>
              <div class="content4_body_text">库存盘点，仓库调拨，销售配货，每个业务发生的库存变动都有对应库存变更日志</div>
            </div>
            <img src="@/assets/Retail17.png"
              alt="">
          </div>
          <div>
            <img src="@/assets/Retail18.png"
              alt="">
            <div>
              <div class="content4_body_subtitle">连锁调货</div>
              <div class="content4_body_text">多个店铺之间分享库存，既提高的库存的利用率，又使每个店铺仓库，销售独立分开</div>
            </div>
          </div>
          <div>
            <div>
              <div class="content4_body_subtitle">货物出入库检查</div>
              <div class="content4_body_text">每件货物出入库，不论是采购，销售配货，还是调拨，都支持出入库检查，可以选择全检或者抽检</div>
            </div>
            <img src="@/assets/Retail19.png">
          </div>
        </div>
      </div>
    </div>
    <div class="centerBox content5">
      <div class="title">
        全方位支持您的生意
        <div></div>
      </div>
      <div class="content5_body">
        <img src="@/assets/Retail20.png"
          class="computer">
        <div class="content5_body_bubble content5_body_topBubble">
          <div class="content5_body_item"
            left>
            <img src="@/assets/Retail21.png">
            <div>
              <div class="content5_body_item_subtitle">权限管理</div>
              <div class="content5_body_item_text">根据海外经营的实际情况，极其精致的权限设置<br>全方位保障您的生意安全</div>
            </div>
          </div>
          <div class="content5_body_item"
            right>
            <div>
              <div class="content5_body_item_subtitle">配套硬件</div>
              <div class="content5_body_item_text">完全配套的原厂定制硬件，售后有保障，软硬件兼容<br>一站式采购，无后顾之忧</div>
            </div>
            <img src="@/assets/Retail24.png">
          </div>
        </div>
        <div class="content5_body_bubble content5_body_middleBubble">
          <div class="content5_body_item"
            left>
            <img src="@/assets/Retail22.png">
            <div>
              <div class="content5_body_item_subtitle">数据报表</div>
              <div class="content5_body_item_text">28种数据报表，实时向您汇报经营状态<br>电脑端手机端都可以查看</div>
            </div>
          </div>
          <div class="content5_body_item"
            right>
            <div>
              <div class="content5_body_item_subtitle">连锁经营</div>
              <div class="content5_body_item_text">多点经营，既支持独立核算，又支持分享库存<br>集中管理减少成本，多店经营形成合力</div>
            </div>
            <img src="@/assets/Retail25.png">
          </div>
        </div>
        <div class="content5_body_bubble content5_body_bottomBubble">
          <div class="content5_body_item"
            left>
            <img src="@/assets/Retail23.png">
            <div>
              <div class="content5_body_item_subtitle">营销工具</div>
              <div class="content5_body_item_text">代金券、优惠券、满减、折扣、会员积分..... <br>各种营销工具帮助您占领市场</div>
            </div>
          </div>
          <div class="content5_body_item"
            right>
            <div>
              <div class="content5_body_item_subtitle">分销经营</div>
              <div class="content5_body_item_text">采购产品后一键导入系统，根据销售情况主动补货<br>提供更好的保障，让分销商专心销售</div>
            </div>
            <img src="@/assets/Retail26.png">
          </div>
        </div>
      </div>
    </div>
    <bottomBar></bottomBar>
    <tryUseDialog :show="show"
      @close="show = false"
      type="register"></tryUseDialog>
  </div>
</template>

<script>
import bottomBar from '@/components/bottomBar'
import tryUseDialog from '@/components/tryUseDialog'
export default {
  name: '',
  props: {},
  components: {
    bottomBar,
    tryUseDialog
  },
  data() {
    return {
      show: false
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toUs() {
      this.$router.push('/aboutUs')
    }
  }
}
</script>

<style scoped lang="scss">
.phone {
  display: none;
}
.banner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  > div {
    position: absolute;
    bottom: 85px;
    width: 100%;
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #ffffff;
    line-height: 54px;
  }
}
.centerBox {
  width: 1200px;
  margin: 0 auto;
}
.langBox {
  width: 100%;
  background: #ebf4ff;
}
.content1 {
  padding: 71px 0 90px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 242px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }
  .content1_body {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .content1_body_item {
      position: relative;
      img {
        width: 319px;
        height: 160px;
      }
      > div {
        padding-top: 37px;
        padding-left: 23px;
        top: 0;
        position: absolute;
        .content1_body_item_subtitle {
          font-size: 21px;
          font-weight: 600;
          color: #ffffff;
          line-height: 21px;
          margin-bottom: 10px;
        }
        .content1_body_item_textDetail {
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 17px;
        }
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        margin-top: 40px;
      }
    }
  }
}
.content2 {
  padding: 33px 0 54px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 242px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }
  .content2_body {
    margin-top: 32px;
    display: flex;
    > div {
      width: 240px;
      height: 240px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      > div {
        padding: 0 20px;
        padding-top: 50px;
        position: absolute;
        width: 200px;
        height: 200px;
        background: rgba($color: #000000, $alpha: 0.5);
        top: 20px;
        left: 20px;
        color: white;
        text-align: center;
        .content2_body_centerBox_subtitle {
          font-size: 19px;
          font-weight: 600;
          color: #ffffff;
          line-height: 21px;
          margin-bottom: 16px;
        }
        .content2_body_centerBox_text {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          line-height: 17px;
        }
      }
    }
  }
}
.content3 {
  padding: 40px 0 89px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 175px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }
  .content3_body {
    margin-top: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content3_body_item {
      text-align: center;
      > img {
        border-radius: 100%;
        width: 187px;
        height: 187px;
        box-shadow: 0px 2px 8px 0px #ceddf1;
      }
      .content3_body_item_subtitle {
        margin-top: 21px;
        margin-bottom: 13px;
        font-size: 19px;
        font-weight: 600;
        color: #333333;
        line-height: 21px;
      }
      .content3_body_item_text {
        width: 256px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }
  }
}
.content4 {
  padding: 33px 0 54px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 175px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }
  .content4_body {
    margin-top: 40px;
    display: flex;
    > div {
      width: 500px;
      img {
        width: 100%;
        vertical-align: top;
      }
      > div {
        padding-top: 63px;
        text-align: center;
        width: 300px;
        height: 187px;
        background: linear-gradient(180deg, #6db7ff 0%, #359bff 100%);
        .content4_body_subtitle {
          font-size: 19px;
          font-weight: 600;
          color: #ffffff;
          line-height: 21px;
          margin-bottom: 13px;
        }
        .content4_body_text {
          padding: 0 10px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 19px;
        }
      }
    }
  }
}

.content5 {
  padding: 32px 0 61px 0;
  .title {
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    letter-spacing: 1px;
    div {
      height: 5px;
      width: 175px;
      margin: 0 auto;
      margin-top: 10px;
      background: #2e93fe;
    }
  }
  .content5_body {
    position: relative;
    height: 422px;
    margin-top: 78px;
    text-align: center;
    img {
      width: 662px;
    }
    .content5_body_bubble {
      height: 104px;
      position: absolute;
      left: 0;
      width: 100%;
      // background: black;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content5_body_bottomBubble,
    .content5_body_topBubble {
      padding: 0 106px;
    }
    .content5_body_topBubble {
      top: 0;
    }
    .content5_body_middleBubble {
      top: calc(50% - 52px);
    }
    .content5_body_bottomBubble {
      bottom: 0;
    }

    .content5_body_item {
      width: 407px;
      height: 104px;
      background: #ffffff;
      box-shadow: 0px 2px 2px 0px #32c5ff;
      display: flex;
      align-items: center;
      padding: 20px 24px;
      img {
        width: 64px;
        height: 64px;
      }
    }
    .content5_body_item[left],
    .content5_body_item[right] {
      text-align: left;
      img {
        margin-right: 15px;
      }
      .content5_body_item_subtitle {
        font-size: 17px;
        font-weight: 600;
        color: #333333;
        line-height: 21px;
        margin-bottom: 8px;
      }
      .content5_body_item_text {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
      }
    }
    .content5_body_item[right] {
      justify-content: right;
      text-align: right;
      img {
        margin-left: 15px;
        margin-right: 0;
      }
    }
  }
}
// .banner {
//   width: 100%;
//   background: linear-gradient(to top, #fffbfb, #ffffff);
//   .center {
//     width: 90%;
//     margin: 0 auto;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .leftText {
//     margin-right: 5%;
//     .title {
//       font-size: 36px;
//       font-weight: 500;
//       color: #092e53;
//       line-height: 50px;
//       letter-spacing: 1px;
//     }
//     .intro {
//       margin-top: 13px;
//       font-size: 20px;
//       font-weight: 400;
//       color: #092e53;
//       line-height: 34px;
//       letter-spacing: 1px;
//     }
//     .bannerBtn {
//       width: 150px;
//       height: 54px;
//       background: linear-gradient(90deg, #ffbe95 0%, #f44244 100%);
//       border-radius: 8px;
//       margin-top: 21px;
//       color: white;
//       text-align: center;
//       line-height: 54px;
//     }
//   }
//   img {
//     width: 48%;
//     margin: 100px 0;
//   }
// }
// .content1 {
//   width: 90%;
//   margin: 0 auto;
//   padding-bottom: 122px;
//   .title1 {
//     text-align: center;
//     font-size: 36px;
//     font-weight: 500;
//     color: #333333;
//     line-height: 42px;
//     letter-spacing: 1px;
//     margin-top: 108px;
//   }
//   .title2 {
//     text-align: center;
//     font-size: 20px;
//     font-weight: 400;
//     color: #555555;
//     line-height: 42px;
//     letter-spacing: 1px;
//     margin-bottom: 68px;
//   }
//   .itemBox {
//     width: 100%;
//     display: flex;
//     justify-content: space-evenly;
//     > .item {
//       min-height: 370px;
//       padding-top: 27px;
//       width: 20%;
//       box-shadow: 0px 2px 10px 0px #fff0ee;
//       border-radius: 16px;
//       // opacity: 0.5;
//       border: 1px solid rgba(249, 124, 106, 0.3);
//       text-align: center;
//       > img {
//         width: 25%;
//         margin-bottom: 15px;
//       }
//       .title {
//         font-size: 28px;
//         font-weight: 500;
//         color: #333333;
//         line-height: 40px;
//         letter-spacing: 1px;
//       }
//       .text {
//         margin: 0 auto;
//         width: 80%;
//         margin-top: 25px;
//         font-size: 20px;
//         font-weight: 400;
//         color: #555555;
//         line-height: 34px;
//         letter-spacing: 1px;
//       }
//     }
//   }
// }

// .severs {
//   width: 100%;
//   background: #fffbfb;
//   position: relative;
//   overflow: hidden;

//   .yuan1,
//   .yuan2 {
//     position: absolute;
//     top: -130px;
//     right: -100px;
//     background: #fef2f1;
//     border-radius: 100%;
//     width: 300px;
//     height: 300px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     > div {
//       border-radius: 100%;
//       width: 220px;
//       height: 220px;
//       background: #fffbfb;
//     }
//   }
//   .yuan2 {
//     width: 260px;
//     height: 260px;
//     top: auto;
//     bottom: -0;
//     left: -150px;
//     > div {
//       width: 180px;
//       height: 180px;
//     }
//   }
//   .center {
//     padding-top: 84px;
//     width: 90%;
//     margin: 0 auto;
//     padding-bottom: 110px;
//     .leftTitle {
//       display: inline-block;
//       width: auto;
//       font-size: 40px;
//       font-weight: 600;
//       color: #000000;
//       line-height: 56px;
//       > div {
//         height: 10px;
//         margin-top: 21px;
//         background: linear-gradient(90deg, #ffbe95 0%, #f44244 100%);
//       }
//       // padding-bottom: 21px;
//       // border-bottom: 10px solid;
//       // border-image: linear-gradient(90deg, #ffbe95 0%, #f44244 100%) 3;
//       // border-left: none;
//       // border-right: none;
//       // border-top: none;
//     }
//     .centerText {
//       margin-top: 61px;
//       display: flex;
//       align-items: flex-start;
//       justify-content: center;
//       > div {
//         text-align: center;
//         font-size: 28px;
//         font-weight: 600;
//         color: #777777;
//         line-height: 40px;
//         .big {
//           font-size: 50px;
//           font-weight: 600;
//           color: #000000;
//           line-height: 70px;
//           display: inline-block;
//           margin: 0 24px;
//         }
//       }
//       .iconfont {
//         font-size: 60px;
//         color: #f8685d;
//       }
//     }
//     .itemBox {
//       margin-top: 74px;
//       width: 100%;
//       display: flex;
//       justify-content: space-evenly;
//       > .item {
//         background: white;
//         position: relative;
//         min-height: 370px;
//         padding-top: 27px;
//         width: 20%;
//         border-radius: 16px;
//         box-shadow: 0px 2px 10px 0px #ffecec;
//         text-align: center;
//         min-height: 488px;
//         > img {
//           width: 25%;
//           margin-bottom: 15px;
//         }
//         .title {
//           font-size: 28px;
//           font-weight: 500;
//           color: #333333;
//           line-height: 40px;
//           letter-spacing: 1px;
//         }
//         .text {
//           margin: 0 auto;
//           width: 80%;
//           margin-top: 25px;
//           font-size: 20px;
//           font-weight: 400;
//           color: #555555;
//           line-height: 34px;
//           letter-spacing: 1px;
//         }
//         .positionImg {
//           position: absolute;
//           width: 80%;
//           bottom: 0;
//           left: 10%;
//         }
//       }
//     }
//   }
// }
// .phone {
//   display: none;
// }

@media screen and (max-width: 768px) {
  .computer {
    display: none;
  }
  .phone {
    display: block;
  }
  .centerBox {
    width: auto;
  }
  .content1 {
    .content1_body {
      justify-content: center;
      .content1_body_item {
        margin-bottom: 10px;
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          margin-top: 0;
        }
      }
    }
  }
  .content2 {
    .content2_body {
      justify-content: center;
      flex-wrap: wrap;
      > div {
        width: 90%;
        border-radius: 30px;
        overflow: hidden;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        > div {
          position: static;
          margin: 0 auto;
          z-index: 0;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .content3 {
    .content3_body {
      flex-wrap: wrap;
      justify-content: center;
      > div {
        margin-bottom: 10px;
      }
    }
  }
  .content4 {
    .content4_body {
      flex-wrap: wrap;
      > div {
        > div {
          width: 100%;
        }
      }
    }
  }
  .content5 {
    padding-bottom: 0;
    .content5_body {
      height: auto;
      .content5_body_bubble {
        height: auto;
        width: 100%;
        flex-wrap: wrap;
        padding: 0;
        position: static;
        .content5_body_item {
          width: 100%;
        }
      }
    }
  }
}
</style>
